@import '../../../styles/variables';

$sidebar-width: 256px;

.custom-categories-content {
  &__header {
    display: flex;
    margin: 0 -24px;

    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;
      margin: 0;
      border-bottom: 1px solid $neutral-color-100;
      font-size: $font-size-medium;
      font-weight: $font-weight-bold;
      color: $neutral-color-800;

      &:first-child {
        flex: 0 0 $sidebar-width;
        width: $sidebar-width;
      }

      &:last-child {
        flex: 1;
      }
    }
  }

  &__middle {
    display: flex;
    margin: 0 -24px;
  }

  &__category-list {
    flex: 0 0 $sidebar-width;
    width: $sidebar-width;
    height: 500px;
    margin: 0;
    padding: 0 $spacer-2xlarge 0;
    overflow-y: auto;

    &__name {
      width: 100%;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 48px;
      padding: 0 $spacer-2xlarge;
      font-weight: $font-weight-bold;
      color: $primary-black;
      background: none;
      border: 0;
      border-radius: 10px;

      svg {
        position: relative;
        top: 2px;
        height: 24px;
        width: 28px;
        margin-right: 10px;

        path {
          fill: $blue-400;
        }

        rect {
          stroke: $blue-400;
          fill: $neutral-color-50;
        }
      }

      &--is-subcategory {
        svg {
          top: 1px;
          height: 20px;
          width: 24px;
          margin-right: 6px;
        }
      }

      &--is-selected {
        background-color: $neutral-color-50;

        svg {
          rect {
            fill: $neutral-color-50;
          }
        }
      }

      &:focus {
        outline: 0;
      }
    }

    > li {
      &:first-child {
        margin: 0 (-$spacer-2xlarge);
        padding: $spacer-medium $spacer-2xlarge;
        border-bottom: 1px solid $neutral-color-100;
        background-color: #fff;
        position: sticky;
        top: 0;
      }
    }

    li {
      list-style: none;
      margin: $spacer-medium 0;
    }

    ul {
      padding-left: 0;

      li {
        button {
          padding-left: $spacer-5xlarge;
        }
      }
    }
  }

  &__category-data {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: $spacer-7xlarge;
    border-left: 1px solid $neutral-color-100;
    overflow-y: auto;

    form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__actions {
    display: flex;
    justify-self: flex-end;
    justify-content: flex-end;
    align-items: center;
    height: 64px;
    margin: auto (-$spacer-7xlarge) (-$spacer-7xlarge);
    padding: 0 $spacer-2xlarge;
    border-top: 1px solid $neutral-color-100;
  }

  &__field {
    width: 100%;
    margin-bottom: $spacer-5xlarge;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      margin-bottom: $spacer-medium;
      font-size: $font-size-small;
      font-weight: $font-weight-bold;
      color: $neutral-color-600;
    }

    input,
    textarea {
      width: 100% !important;
    }

    .flight-multi-select {
      button {
        width: 100%;
        justify-content: flex-start;

        svg {
          margin-left: auto;
        }
      }
    }

    .flight-text-area {
      padding: 0;

      &__footer {
        width: 100%;
        margin: 0;
        padding: 2px 4px;
        background-color: $neutral-color-100;
        text-align: right;
      }
    }
  }

  &__flex {
    display: flex;
    width: 100%;
    gap: $spacer-7xlarge;

    > :nth-child(2) {
      flex: 1;
    }
  }
}
