@import 'src/styles/variables';
@import 'src/styles/mixins';

@keyframes content-placeholder-animation {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(300%);
  }
}

.content-placeholder-background {
  animation: content-placeholder-animation 1s linear infinite;
  background: -moz-linear-gradient(
    left,
    rgba(15, 15, 15, 0) 0%,
    rgba(219, 219, 219, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(15, 15, 15, 0) 0%,
    rgba(219, 219, 219, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(15, 15, 15, 0) 0%,
    rgba(219, 219, 219, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000f0f0f', endColorstr='#00ffffff',GradientType=1 );
  display: block;
  height: inherit;
  left: 0;
  position: absolute;
  top: 0;
  width: 70%;
  will-change: transform;
}

.content-placeholder-text {
  background-color: #ebebeb;
  display: block;
  height: var(--block-height);
  overflow: hidden;
  position: relative;
  width: 50%;
  opacity: 0.7;
  margin-bottom: 4px;

  &:last-of-type {
    width: 35%;
  }
}
.loading__icon_wrapper {
  position: relative;
  display: inline-flex;
  &.fullscreen {
    display: flex;
    flex-direction: column;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    top: 0;
    position: fixed;
    z-index: 95;
    background-color: rgba(255, 255, 255, 0.95);
  }
  &_block {
    display: flex;
  }

  svg {
    position: relative;
    visibility: visible;
    fill: map-get($blues, '500');
    // check _mixins.scss for more details
    @include animation(spin, 0s, 0.75s, linear, infinite, normal, none, running);
  }
}
