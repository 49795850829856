@import '../../styles/variables';

.manage-templates-page {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  height: 100%;
  border-left: 1px solid $neutral-color-100;

  &__topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $spacer-5xlarge;
    padding: 0 $spacer-2xlarge;
    line-height: 1;

    .search-bar {
      .flight-text-input {
        margin: 0;
      }
    }

    &__buttons {
      margin-left: auto;

      button {
        margin-left: $spacer-2xlarge;

        &:disabled {
          opacity: 0.66;
          cursor: not-allowed;
        }

        &:nth-child(2) {
          // fix svg viewbox, will be fixed in DS on the future
          svg {
            position: relative;
            top: 2px;
            left: 1px;
            width: 24px;
            height: 24px;
          }
        }
      }

      svg {
        fill: currentColor;
        height: 24px;
      }
    }

    &__list-view {
      display: flex;
      align-items: center;
      color: $neutral-color-400;
    }

    &__radio-group {
      display: flex;
      margin-left: $spacer;
    }

    &__radio-button {
      &:first-child {
        label {
          border-radius: 5px 0 0 5px;
        }
      }

      &:last-child {
        label {
          border-radius: 0 5px 5px 0;
        }
      }

      input {
        position: absolute;
        left: -9999px;
        visibility: hidden;

        &:checked {
          + label {
            background-color: $blue-500;
            color: #ffffff;
          }
        }

        + label {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 40px;
          margin: 0;
          border: 1px solid $blue-500;
          font-size: $font-size-small;
          font-weight: bold;
          color: $blue-500;
          cursor: pointer;
        }
      }
    }
  }

  &__actions {
    padding-top: 40px;
    margin: 0 $spacer-2xlarge;
    display: flex;
    justify-content: space-between;

    .flight-button {
      margin-left: 20px;
    }
  }

  &__content {
    margin-top: $spacer-7xlarge;
    padding: 0 $spacer-2xlarge;

    &__gallery-view {
      padding-bottom: $spacer-2xlarge;
    }
  }
  .import_button {
    position: relative;
    .import_error_icon {
      cursor: pointer;
      position: absolute;
      left: 15px;
      top: -20px;
      background-color: #fff;
      display: block;
      border-radius: 20px;
      svg {
        width: 20px;
        height: 20px;
        fill: map-get($reds, '500');
      }
      .flight-tooltip__tooltip {
        background-color: #fff;
        color: $neutral-color-700;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
      }
    }
  }
}
