@import '../../styles/variables';
@import '../../styles/fonts';

.import-page__modal {
  .flight-modal-wrapper .flight-modal .flight-modal__content {
    padding: 0 0 $spacer-2xlarge * 1.5 0;
  }
  .import-page__modal__header {
    padding-top: $spacer-large * 3.6;
    padding-left: $spacer-large;
    @extend .flight-font--large;
    @extend .flight-font--bold;
  }
  .import-templates-page {
    height: 100%;
    border-left: 1px solid $neutral-color-100;
  }
  &-alert {
    .flight-modal__content {
      min-height: 140px !important;
      > div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .flight-button--primary {
          background-color: map-get($reds, '300');
          &:hover,
          &:active {
            background-color: map-get($reds, '400');
          }
        }
      }
    }
  }

  &__content {
    &__tabstrip {
      margin: 0 40px;
      &__container {
        justify-content: center;
        display: flex;
        &__bulk-actions {
          display: flex;
          align-items: center;
          .flight-button {
            border: none;
            font-weight: normal;
            color: #555;
            box-shadow: none;
            svg {
              fill: map-get($neutral-colors, '300');
            }
          }
        }
        &__tabs {
          padding-top: 26px;
          &.row {
            margin-left: 0;
            margin-right: 0;
            .col {
              padding-left: 0;
              padding-right: 0;
            }
          }
          &__label {
            text-decoration: none !important;
          }
        }
        &__content {
          width: 100%;
        }
      }
    }
    .nav-tabs {
      width: 100%;
      justify-content: space-between;
      border: none;
      padding: $spacer-2xlarge * 1.5;

      .nav-item > div {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #5a5a5a;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        clip-path: polygon(calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0% 100%, 10px 50%, 0% 0%);
      }
      .nav-item {
        filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.1));
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 33%;
        max-width: 33%;
        // hide conflicts tab
        &:nth-child(2) {
          display: none;
        }
        &:first-child {
          padding: 0;
        }
        &:first-child {
          & > div {
            clip-path: polygon(calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0% 100%, 0 50%, 0% 0%);
          }
        }
        &:last-child {
          & > div {
            clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 10px 50%, 0% 0%);
          }
        }
      }
    }
    .nav-tabs .nav-link {
      border: 1px solid transparent !important;
      cursor: pointer;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: map-get($primary-colors, 'black');
      padding: 6px 10px;
      min-height: 36px;
      min-width: 46px;
      opacity: 1;
      width: 100%;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      position: relative;
      background: #fff;
      &.disabled {
        background: #fff;
        color: map-get($primary-colors, 'black');
      }
      &.done {
        border-color: map-get($blues, '50') !important;
        color: map-get($primary-colors, 'black');
        background: map-get($blues, '50');
        &.conflicts {
          border-color: map-get($reds, '50') !important;
          background: map-get($reds, '50');
        }
      }
      &.active {
        border-color: map-get($blues, '500') !important;
        color: #fff;
        background-color: map-get($blues, '500');
        &.conflicts {
          // color: #fff;
          border-color: map-get($reds, '500') !important;
          background: map-get($reds, '500');
        }
      }
    }
  }

  .flight-modal-wrapper .flight-modal {
    &--large {
      max-width: 1184px !important;
      transform: none;
      top: $spacer-double;
    }

    &__header {
      text-align: center;
      box-shadow: 0 1px 1px map-get($neutral-colors, '200');
      button {
        display: none;
      }
    }
  }
  .flight-modal-wrapper .flight-modal .flight-modal__content {
    max-height: calc(100vh - 192px);
    margin-bottom: 0 !important;
  }

  .flight-modal-wrapper {
    .flight-modal__footer {
      padding: 0px;
    }
  }

  .import-page__modal__row {
    &__icon {
      width: 65px;
      height: 65px;
      border: 1px solid $border-color;
      padding-right: $spacer-large * 3.1;
      padding-top: $spacer-large * 0.6;
      padding-left: $spacer-large * 0.6;
      padding-bottom: $spacer-large * 2.6;
    }
    &__label {
      padding-left: $spacer-large * 2.1;
      padding-top: 0px !important;
      @extend .flight-font--small;
      @extend .flight-font--bold;

      &__field {
        padding-top: $spacer-small;
      }
    }
  }

  .modal-footer {
    position: relative;
    &__template-amount {
      position: absolute;
      left: 0;
      margin-left: 32px;
    }
  }

  .import-page__modal__radio__label {
    @extend .flight-font--small;
    @extend .flight-font--bold;
  }

  .import-page__modal__radio__group {
    @extend .flight-font--small;
    @extend .flight-font--bold;
    &__icon {
      width: 169px;
      height: 42px;
      border-radius: 5px;
      border: 1px solid $border-color;
      &:hover {
        border: 1px solid $blue-300;
      }
      padding: $spacer-large * 0.7;
      padding-top: 10px;
      padding-bottom: 0px;
      margin: $spacer-large;
      margin-left: -1px;
      @extend .flight-font--small;
    }
  }

  .import-page__modal__container {
    height: 593px;
    width: 100%;
    position: relative;
    float: right;
  }
}
