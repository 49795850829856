@import 'src/styles/variables';
.btn {
  max-height: 40px;
}

.email-usage-alerts {
  width: 55%;

  .flight-modal-wrapper .flight-modal--medium {
    max-width: 450px !important;
  }
  &__header {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
  }
  &__title {
    font-size: 14px;
    color: $neutral-color-600;
    margin-bottom: 0.5rem;
  }
  &__menu {
    cursor: pointer;
  }
  &__img {
    min-width: 20px;
    max-width: 20px;
    float: right;
  }
  &__error {
    color: $error-color;
  }
  &__addbtn {
    float: right;
  }
  &__percentage {
    margin-top: $spacer-large;
  }
  &__receipients {
    margin-top: $spacer-large;
  }

  &__table {
    &__icon {
      margin-left: -30px;
    }

    .flight-table-row__cell {
      padding: $spacer - 3;

      &:first-child {
        width: 40%;
      }
    }
  }

  @media screen and (max-width: $medium) {
    width: 100%;
  }
}
