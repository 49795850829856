@import 'styles/_variables';
@import 'styles/_fonts';

@mixin reset-margin {
  margin-left: 0;
  margin-right: 0;
}
@mixin reset-padding {
  padding-left: 0;
  padding-right: 0;
}
.app-config-wrapper {
  max-width: 1400px;
  .app-config {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    border: 1px solid $neutral-color-100;
    border-radius: 0.25rem;
    > div {
      padding: 1.25rem;
      border-right: 1px solid $neutral-color-100;
      &:last-child {
        border-right: none;
      }
      &.error {
        color: $error-color;
      }
    }
    .push-settings {
      flex-basis: auto;
      .container {
        display: grid;
        grid-template-rows: auto auto 1fr;
        grid-gap: 16px;
        height: 100%;
        @include reset-padding;
        .row {
          @include reset-padding;
          @include reset-margin;
          &:last-child {
            align-self: end;
          }
        }
      }
      &__modal {
        padding: 10px;
        &__content {
          display: grid;
          &.field-group {
            grid-gap: 8px;
          }
          .flight-text-area {
            padding-bottom: 0;
          }
        }
      }
      .flight-modal-wrapper .flight-modal--medium {
        max-width: 445px !important;
      }

      .flight-modal__footer {
        padding: 0px !important;
      }
      &__icon {
        cursor: pointer;
        margin-left: 20px;
        width: 18px;
        height: 18px;
      }
      &__header {
        font-weight: $font-weight-bold;
        display: inline-block;
        vertical-align: top;
        padding-left: $spacer-medium;
        margin-top: $spacer-medium;
        text-align: center;
      }
      &__helper {
        @extend .flight-font--xsmall;
        color: $neutral-color-500;
      }
      &__body {
        margin-top: $spacer-xlarge;
        color: $neutral-color-400;
        font-size: $font-size-small;
      }
      &__content {
        width: 100%;
      }
      &__info {
        width: 100%;
        @extend .flight-font;
        margin-bottom: 16px;
        > span {
          display: block;
        }
        .property {
          @extend .flight-font--xsmall;
          text-transform: uppercase;
          color: $neutral-color-400;
          margin-bottom: 2px;
        }
        .value {
          @extend .flight-font--small;
          background: $neutral-color-50;
          color: $neutral-color-600;
          border-radius: 5px;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          &.key {
            font-weight: normal;
            color: $neutral-color-600;
          }
          .helper {
            color: $neutral-color-400;
            font-size: $font-size-small;
            font-weight: normal;
          }
        }
      }
      &__btn--blue {
        color: $primary-blue !important;
      }
      &__btn--red {
        color: $secondary-red !important;
      }
    }
  }
  .pt-5 {
    width: 100%;
    display: grid;
    grid-gap: 42px;
  }
}
@media only screen and (max-width: 1280px) {
  .app-config-wrapper {
    .app-config {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }
}
@media only screen and (max-width: 1080px) {
  .app-config-wrapper {
    max-width: 550px;
    .app-config {
      grid-template-columns: 100%;
      > div {
        padding: 1.25rem;
        border-right: none;
        border-bottom: 1px solid $neutral-color-100;
        &:last-child {
          border-bottom: none;
        }
      }
      .push-settings {
        &__info {
          max-width: 400px;
        }
        .container {
          grid-template-rows: unset;
          grid-template-columns: 1fr 180px;
          .row {
            display: block;
            &:first-child {
              grid-column: 1 / span 2;
            }
            &:nth-child(2) {
              grid-column: 1 / span 1;
            }
            &:last-child {
              justify-self: end;
              padding-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
