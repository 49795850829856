@import '../../../styles/variables';

.process-templates{
  overflow: auto;
  text-align: center;
  *{
    display: block;
  }

  &__image{
    margin: 20px auto;
    width: 234px;
  }

  &__text{
    font-size: $font-size-medium;
  }

  &__error {
    &__container{
      height: 80px;
      margin: 24px auto 0 auto;
      overflow: auto;
      border: 1px solid $secondary-red;
      border-radius: 8px;
      width: 575px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  &__loading-circle{
    margin: 20px auto;
  }
}