@import '../../../styles/variables';

// Component
.conflicts-resolution {
  &__list {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    padding-top: 48px;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 24px;

    position: absolute;
    height: 50px;
    left: 0%;
    right: 0%;
    bottom: 0px;

    background: #ffffff;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
  }
  &__title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 20px;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid map-get($neutral-colors, '100');
    svg {
      width: 24px;
      height: 24px;
    }
    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      width: calc(50% - 15px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      &:first-child {
        svg {
          .stroke {
            stroke: $primary-black;
            &.fill {
              fill: $primary-black;
            }
          }
        }
      }
    }
    .conflict-list-item {
      flex: 0 0 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__templates {
    height: 366px;
    overflow: auto;
    margin-bottom: 50px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 20px;
    border-bottom: 1px solid map-get($neutral-colors, '50');
    position: relative;
    flex-wrap: wrap;
    .conflict-list-item {
      flex: 0 0 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid map-get($reds, '400');
      border-radius: 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 11.5px;
      line-height: 15px;
      padding: 3px;
      margin-top: 16px;
      gap: 3px;
      font-style: italic;
      svg {
        width: 14px;
        height: 14px;
        fill: map-get($reds, '500');
      }
    }
  }
  &__column {
    display: flex;
    flex-direction: column;
    width: calc(50% - 15px);
    &.disabled {
      .template {
        &-image,
        &-infos {
          transition: opacity 0.3s;
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }
    &-content {
      display: flex;
      flex-direction: row;
      .template {
        &-manual-select {
          margin-right: 16px;
          input {
            cursor: pointer;
          }
        }
        &-image {
          flex: 0 0 148px;
          height: 89px;
          position: relative;
          border: 1px solid map-get($blues, '400');
          border-radius: 3px;
          background-color: map-get($neutral-colors, '50');
          // Fill image to container
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          img {
            object-fit: cover;
            width: 158px;
            height: 89px;
          }
          // No image
          &:empty {
            &::after {
              content: 'No image';
              position: absolute;
              width: 100%;
              text-align: center;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              color: map-get($neutral-colors, '400');
            }
          }
        }
        &-infos {
          margin-right: 16px;
          h5 {
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
            color: $primary-black;
            margin: 0;
          }
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
              margin-top: 6px;
              font-size: 12px;
              line-height: 15px;
              color: map-get($neutral-colors, '700');
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}
