@import '../../styles/variables';

.account-domain-access {
  &__header {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
  }
  &__description {
    font-size: $font-size-small;
    color: $neutral-color-600;
  }
  &__link {
    font-size: $font-size-small;
    color: $primary-blue;
  }
  &__btn {
      float: right;
      bottom: $spacer-2xlarge * 2;
  }
}
