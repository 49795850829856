.custom-categories-modal {
  .flight-modal-wrapper {
    .flight-modal--large {
      max-width: 1184px !important;

      .flight-modal__header,
      .flight-modal__footer {
        padding: 0;
      }

      .flight-modal__content {
        padding-top: 0;
        max-height: 90vh;
      }
    }
  }
}