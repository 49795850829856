@import '../../styles/variables';

.signin-provider-table {
  display: flex;
  width: 60%;
  border: 1px solid $neutral-color-200;

  .flight-table-header {
    border-bottom: 1px solid $neutral-color-200;
    padding: $spacer - 3;
    background-color: $neutral-color-50;
    font-weight: $font-weight-regular;
    font-size: $font-size-small;
    color: $neutral-color-700;
  }

  &__logo {
    margin-right: $spacer - 3;
  }

  img.logo {
    width: 24px;
    height: 24px;
    margin-right: $spacer - 3;
  }

  &__status-text {
    font-size: $font-size-small;
    padding-right: $spacer * 5;
    color: $neutral-color-400;
    &--enabled {
      color: $green-500;
    }
  }

  &__edit-provider {
    padding: 8px;
  }

  @media screen and (max-width: $medium) {
    width: 100%;
    transition-property: width;
    transition-duration: 1s;
  }
}
