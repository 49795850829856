@import '../../../styles/variables';

.create-push-template {
  $margin-align-left: -15px;
  &__fields {
    color: $primary-black;
    font-weight: $font-weight-bold;
    font-size: $font-size-small;
    margin-left: $margin-align-left;
  }
  &__description {
    color: $neutral-color-700;
    font-size: $font-size-small;
    margin-left: $margin-align-left;
  }

  &__table {
    margin-left: $margin-align-left;
    margin-right: 50px;
    border: 1px solid $neutral-color-200;
    .flight-table-header {
      border-bottom: 1px solid $neutral-color-200;
      background-color: $neutral-color-50;
      font-weight: $font-weight-regular;
      color: $neutral-color-700;
    }
  }

  &__page-ftr {
    margin-top: 5%;
    &__save-btn {
      margin-left: $spacer-large;
    }
  }

  &__add {
    margin-left: -20px !important;
    > .flight-button--minor {
      background-color: $blue-500;
      color: $primary-white;
    }
    > .flight-button--minor:hover:enabled {
      background-color: $blue-600;
      color: $primary-white;
      outline: 2px solid $blue-800;
    }
    > .flight-button--minor:focus:enabled {
      background-color: $primary-blue;
      color: $primary-white;
      outline: 2px solid $blue-800;
    }
    > .flight-button:disabled {
      background-color: $neutral-color-300;
    }

    > .flight-button--minor svg {
      fill: $primary-white !important;
    }
  }

  &__modal {
    padding: $spacer-large;

    &__content {
      padding-top: 0px;
      padding-left: $spacer-large * 1.5;
      &__title {
        color: $neutral-color-600;
      }
      &__menu {
        margin-left: -10px;
        padding-top: 55px;
        position: fixed !important;
      }
      &__checkbox {
        padding-top: 20px;
        position: relative;
      }
      &__question-mark {
        margin-top: 22px;
        margin-left: 10px;
      }
      &__array-fields {
        margin-left: $margin-align-left;
        position: relative;
        > .array-fields .flight-text-input__input__clear-icon {
          margin-left: 68%;
        }
      }
    }
    &__footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      padding: 0.75rem;
      border-top: 1px solid #dee2e6;
      border-bottom-right-radius: calc(0.3rem - 1px);
      border-bottom-left-radius: calc(0.3rem - 1px);
      &__save-btn {
        margin-left: $spacer-large;
      }
    }
  }
}

.flight-modal-wrapper .flight-modal--large {
  max-width: 648px !important;
  max-height: 900px !important;
}

.flight-text-area {
  padding-bottom: 0px;
}
