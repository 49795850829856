@import 'styles/_variables';

.content-review {
  position: relative;

  &__table {
    max-height: 515px;
    overflow: auto;

    thead {
      background-color: $blue-50;
      position: sticky;
      top: 0;
      z-index: 99;
    }

    .flight-table-header {
      white-space: nowrap;
      padding-left: 6px;
      padding-right: 6px;
    }

    .flight-table-row__cell {
      padding-left: 6px;
      padding-right: 6px;

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        max-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:nth-child(2) {
        width: 40%;
      }

      &:nth-child(n + 3) {
        width: 15%;
      }
    }

    &__type {
      font-size: $font-size-small;
      font-weight: $font-weight-bold;

      &--flybits{
        color: $teal-700;
      }

      &--custom{
        color: $yellow-700;
      }
    }

    &__action {
      font-size: $font-size-small;
      font-weight: $font-weight-bold;
      text-transform: capitalize;
    }
  }
}