@import '../../styles/variables';

.concierge-category-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  padding: 0px 40px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $font-size-medium;

    &__text {
      margin-top: 40px;
    }

    &__link {
      margin-top: 20px;
      color: $primary-blue;
      cursor: pointer;
    }

    &__btn {
      margin-top: 50px;
    }
  }
}
