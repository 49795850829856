@import '../../styles/variables';

.context-attributes-page {
  border-left: 1px solid $neutral-color-100;
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      padding: 0;
    }

    &__links {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-top: 20px;
      margin-right: 1%;

      @media screen and (max-width: 1050px) {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    &__link {
      margin-left: 10px;

      @media screen and (max-width: 1050px) {
        margin-bottom: 5px;
      }
    }
  }

  &__tabstrip {
    &__container {
      justify-content: center;
      display: flex;
      &__tabs {
        padding-top: 46px;
        &__label {
          margin-left: -12px;
          text-decoration: none !important;
        }
      }
    }
  }
  .flight-nav .route-content {
    overflow: hidden !important;
  }
  .nav-tabs {
    min-width: 100vw;
  }
  .nav-tabs .nav-link {
    border: none !important;
    margin-left: 42px;
    cursor: pointer;
    &.active {
      border-bottom: 4px solid #0851df !important;
      width: 60%;
      border-color: #0851df !important;
      color: #051336;
      font-weight: 700;
      margin-left: 42px;
    }
  }
}
