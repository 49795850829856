@import '../../../styles/variables';

.api-key-modal {
  &__content {
    padding-bottom: $spacer-2xlarge;
    &__header-logo {
      margin-left: $spacer-2xlarge;
    }
    &__header-text {
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      padding-left: $spacer-large;
    }
    &__toggle {
      padding-left: $spacer-2xlarge * 16;
      padding-top: $spacer-2xlarge;
      padding-bottom: $spacer-2xlarge;

    }
    &__text-block {
      padding-top: $spacer-2xlarge;
      padding-left: $spacer-xlarge;
      padding-bottom: $spacer-2xlarge * 4;
      &__display-uuid {
        padding: 11px;
        width: 383px;
        height: 40px;
        color: $neutral-color-500;
        background-color: $neutral-color-50;
        &__img {
          cursor: pointer;
          float: right;
        }
      }
      &__delete {
        margin-top: 10px;
        cursor: pointer;
        margin-left: $spacer-xlarge;
      }
    }
    &__gen-btn {
      padding-top: $spacer-large;
      padding-left: $spacer-large;
      padding-bottom: $spacer-2xlarge * 4;
      .flight-button {
        width: 149px;
        height: 40px;
        border-radius: 4px !important;
        background-color: $blue-50 !important;
        color: $primary-blue;
        font-size: $font-size-small;
      }
    }
    &__warning-banner {
      height: 63px;
      background-color: $yellow-50;
      padding-top: $spacer-xsmall;
      &__icon {
        margin-left: 10px;
        margin-top: 10px;
        margin-right: $spacer-small;
      }
      &__text {
        padding: $spacer-small;
        margin-left: 40px;
        margin-top: -25px;
        font-size: $font-size-small;
        color: $neutral-color-900;
        flex-wrap: initial;
      }
    }
  }

  .flight-modal-wrapper .flight-modal--small {
    max-width: 490px !important;
  }

  .flight-modal__footer {
    padding: 0px !important;
  }
}
