@import '../../../styles/variables';

.floating-save-bar {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 256px);
  background-color: $neutral-color-0;
  padding: 30px;
  border-left: 1px solid $neutral-color-200;
  border-top: 1px solid $neutral-color-200;
  box-shadow: 10px -5px 15px rgba(0, 0, 0, 0.15);

  &__text {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    color: $primary-black;
  }

  &__submit-btn {
    margin-right: 20px;
  }

  @media screen and (max-width: 899px) {
    width: calc(100% - 64px);
  }
}
