@import '../../styles/variables';

.content-templates {
  padding: 3%;
  &__greeting-text {
    color: $neutral-color-700;
    font-size: $font-size-small;
  }

  &__header {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &__btn {
      @media screen and (max-width: 1100px) {
        margin-bottom: 1%;
      }
    }
    &__right {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      &__btn {
        margin-right: 2%;
      }
      @media screen and (max-width: 1100px) {
        width: 80%;
        justify-content: flex-start;
      }
    }
    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }
  }

  &__table {
    max-height: 500px;
    margin-top: 30px;
    border: 1px solid $neutral-color-200;
    overflow: auto;

    .flight-table {
      min-width: 900px;

      .flight-table-header {
        position: sticky;
        z-index: 1;
        top: 0;
        font-weight: $font-weight-regular;
        color: $neutral-color-700;
        background-color: $neutral-color-50;
        border-bottom: 1px solid $neutral-color-200;
      }

      ul.flight-overflow-menu__dropdown-list--opened {
        position: fixed;
        right: 175px;
      }
    }

    .flight-checkbox {
      position: relative !important;
    }

    &__name-container {
      //Quickfix: Sai : stop names overlaping with icons, must investigate why and stop
      // margin-left: -15%;

      @media screen and (max-width: 1232px) {
        // margin-left: -10%;
      }
      @media screen and (max-width: 900px) {
        margin-left: 0%;
      }
      &__name {
        font-size: 14px;
        font-weight: 700;
        color: $primary-blue;
        cursor: pointer;
        &__label {
          font-size: $font-size-xsmall;
          color: $neutral-color-700;
        }
      }
    }

    &__description {
      max-width: 300px;
    }

    &__tooltip {
      .flight-tooltip__tooltip {
        max-width: 260px;
      }
    }
  }
}
