@import 'variables';

// Font Styles
.flight-font {
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  font-style: normal;
  font-size: $font-size-base;
  line-height: $line-height-base;

  // Font size modifiers
  &--code {
    font-family: $font-family-mono;
    font-size: $font-size-code;
    line-height: $line-height-code;
  }

  &--xsmall {
    font-size: $font-size-xsmall;
    line-height: $line-height-xsmall;
  }

  &--small {
    font-size: $font-size-small;
    line-height: $line-height-small;
  }

  &--medium {
    font-size: $font-size-medium;
    line-height: $line-height-medium;
  }

  &--large {
    font-size: $font-size-large;
    line-height: $line-height-large;
  }

  &--xlarge {
    font-size: $font-size-xlarge;
    line-height: $line-height-xlarge;
  }

  &--2xlarge {
    font-size: $font-size-2xlarge;
    line-height: $line-height-2xlarge;
  }

  &--3xlarge {
    font-size: $font-size-3xlarge;
    line-height: $line-height-3xlarge;
  }

  // Font weight modifiers
  &--normal {
    font-weight: $font-weight-base;
  }

  &--bold {
    font-weight: $font-weight-bold;
  }

  &--xbold {
    font-weight: $font-weight-xbold;
  }
}