@import '../../styles/variables';

.authentication-page {
  border-left: 1px solid $neutral-color-100;
  height: 100%;

  &__content {
    padding: 3%;
  }
}
