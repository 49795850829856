@import '../../styles/variables';

.push-page {
  border-left: 1px solid $neutral-color-100;
  height: 100%;
  &__tabstrip {
    &__container {
      justify-content: center;
      display: flex;
      &__tabs {
        padding-top: 46px;
        &.row {
          margin-left: 0;
          margin-right: 0;
          .col {
            padding-left: 0;
            padding-right: 0;
          }
        }
        &__label {
          margin-left: -12px;
          padding-bottom: 5px;

          text-decoration: none !important;
          &__active {
            margin-left: -12px;
            padding-bottom: 5px;
            border-bottom: 4px solid #0851df !important;
          }
        }
      }
      &__content {
        padding: 3%;
        h2 {
          font-size: medium;
          font-weight: bold;
        }
      }
    }
  }

  &__manage {
    margin-left: 40px;
  }
  .flight-nav .route-content {
    overflow: hidden !important;
  }
  .nav-tabs {
    min-width: 100%;
  }
  .nav-tabs .nav-link {
    border: none !important;
    margin-left: 42px;
    cursor: pointer;
    &.active {
      border-bottom: 1px solid $neutral-color-100 !important;
      border-bottom: 1px solid $neutral-color-200;
      color: #051336;
      font-weight: 700;
    }
  }

  .flight-text-input__input {
    width: 100%;
  }
}
