@import 'styles/_variables';

.service-accounts-table {
  max-height: 500px;
  margin-top: 40px;
  border: 1px solid $neutral-color-200;
  overflow-y: auto;

  .flight-table {
    tbody {
      td {
        vertical-align: baseline;
      }

      .flight-table-row__cell {
        vertical-align: middle;

        * {
          vertical-align: baseline;
        }
      }
    }
  }

  &__name {
    font-size: 14px;
    font-weight: $font-weight-regular;
    color: $neutral-color-900;

    p {
      margin: 0;
    }
  }

  &__options {
    .flight-overflow-menu__dropdown-list {
      &__group__option:last-of-type {
        color: $secondary-red;

        &:hover {
          color: $primary-white;
          background-color: $secondary-red;
        }
      }
    }
  }
}