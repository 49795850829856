@import '../../../styles/variables';

.silent-push-settings {
  &__layout {
    padding: 20px;
  }

  &__footer {
    float: right;
  }
  
  &__header {
    display: inline-block;
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
  }
  &__description {
    font-size: $font-size-small;
    padding-top: $spacer-medium;
    color: $neutral-color-600;
  }
  &__toggle {
    float: right;
  }
  &__link {
    font-size: $font-size-small;
    color: $primary-blue;
  }
  &__btn {
    float: right;
    padding-bottom: $spacer-2xlarge;
  }
}
