@mixin prefix-property($name, $value) {
  -webkit-#{$name}: $value;
  -moz-#{$name}: $value;
  -ms-#{$name}: $value;
  -o-#{$name}: $value;
  #{$name}: $value;
}

@mixin animation(
  $animation,
  $delay,
  $duration,
  $timing_function,
  $iteration_count,
  $direction,
  $fill_mode,
  $play_state
) {
  @include prefix-property(animation-name, $animation);
  @include prefix-property(animation-delay, $delay); // default 0s
  @include prefix-property(animation-duration, $duration); // default 0s
  @include prefix-property(animation-timing-function, $timing_function); // default ease-in
  @include prefix-property(animation-iteration-count, $iteration_count); // default 1
  @include prefix-property(animation-direction, $direction); // default normal
  @include prefix-property(animation-fill-mode, $fill_mode); // default none
  @include prefix-property(animation-play-state, $play_state); // default running
}
