@import '../../styles/variables';


.webhooks {
  padding: 40px;
  border-left: 1px solid $neutral-color-100;
  overflow: hidden;
  height: 100%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__page-header {
    margin-left: -2px;
    padding: 0px;
  }

  &__search-bar {
    margin-top: 45px;
  }
}