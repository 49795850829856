@use "sass:selector";
@import '../../../styles/variables';

.hideNames {
  display: none;
}
.double-array-fields {
  padding-bottom: $spacer-2xlarge * 2;
  &__field-array {
    margin-top: -54px;
  }
  &__index-field-wrapper {
    display: flex;

    .flight-text-input-wrapper {
      max-width: 190px;
    }
  }
  &__text-field {
    display: inline-block;
  }
  &__container {
    display: flex;
    padding: 10px 0;
  }
  &__index-box {
    width: 40px;
    height: 40px;
    background: $neutral-color-50;
    z-index: 1;
    margin-top: 0.08px;
    padding: 5px;
    padding-top: 10px;
    padding-left: 15px;
    border: 1px solid $neutral-color-200;
  }
  &__btn-no-display {
    position: relative;
    width: 0;
    height: 0;
    visibility: hidden;
  }
  &__error {
    color: $red-500;
    margin-top: -10px;
  }
  &__header {
    display: flex;
    margin-bottom: 15px;
    justify-content: flex-end;
    width: 460px;
    background-color: $neutral-color-50;
    border: 1px solid $neutral-color-200;

    &__text {
      width: 230px;
      max-width: 280px;
      padding: 10px;
      font-size: $font-size-small;
      color: $neutral-color-700;
      border-left: 1px solid $neutral-color-200;

      &:first-of-type {
        width: 190px;
      }
    }
  }
  .flight-text-input__input:hover {
    border-color: $neutral-color-200;
  }

  .flight-text-input__input {
    border: 1px solid $neutral-color-200;
    border-left: none;
    padding-left: 7px;
    font-size: $font-size-small;
    background-color: white;
    width: 100%;
    margin-bottom: -4px;
    border-radius: 0 !important;

    &__double_clear-icon {
      margin-top: 9px;
      top: 40px;
      position: relative;
      cursor: pointer;
      z-index: 1;
      left: 50px;
      margin-left: 385px;
      width: 18px;
      height: 18px;
    }
  }

  .flight-text-input__border {
    background-color: transparent !important;
  }

  &__second-field {
    .flight-text-input__input {
      padding-right: 30px;
    }
  }

  &__add-field {
    background: none;
    border: none;
    font-size: $font-size-small;
    font-weight: 700;
    color: $primary-blue;
  }
}
