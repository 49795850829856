@use "sass:map";

$spacer: 15px;
$spacer-half: floor($spacer * 0.5);
$spacer-double: floor($spacer * 2);
$spacer-section: 2rem;

$error-color: #dc3616;
$placeholder-background-color: rgba(5, 19, 54, 0.05);
$success-color: #00ab50;
$text-color: #000000;
$text-muted-color: lighten($text-color, 50%);

$grid-breakpoint-sm: 576px;
$grid-breakpoint-md: 768px;

// Colors

// primary colors
$primary-blue: #0851df !default;
$primary-black: #051336 !default;
$primary-white: #ffffff !default;

// stylelint-disable-next-line scss/dollar-variable-default
$primary-colors: (
  'base': $primary-blue,
  'blue': $primary-blue,
  'black': $primary-black,
  'white': $primary-white,
) !default;

// secondary colors
$secondary-red: #ff541e !default;
$secondary-yellow: #ffc107 !default;
$secondary-green: #16d892 !default;
$secondary-purple: #7c4dff !default;
$secondary-teal: #10b2fb !default;

// stylelint-disable-next-line scss/dollar-variable-default
$secondary-colors: (
  'red': $secondary-red,
  'yellow': $secondary-yellow,
  'green': $secondary-green,
  'purple': $secondary-purple,
  'teal': $secondary-teal,
) !default;

// neutral/text colors - fading dark base using shades
$neutral-color-base: map-get($primary-colors, 'black') !default; //#051336
$neutral-color-900: #1e2b4a !default;
$neutral-color-800: #37425e !default;
$neutral-color-700: #505a72 !default;
$neutral-color-600: #697186 !default;
$neutral-color-500: #82899b !default;
$neutral-color-400: #9ba1af !default;
$neutral-color-300: #b4b8c3 !default;
$neutral-color-200: #cdd0d7 !default;
$neutral-color-100: #e6e7eb !default;
$neutral-color-50: #f2f3f5 !default;
$neutral-color-0: map-get($primary-colors, 'white') !default; //#FFFFFF

// stylelint-disable-next-line scss/dollar-variable-default
$neutral-colors: (
  'base': $neutral-color-base,
  '900': $neutral-color-900,
  '800': $neutral-color-800,
  '700': $neutral-color-700,
  '600': $neutral-color-600,
  '500': $neutral-color-500,
  '400': $neutral-color-400,
  '300': $neutral-color-300,
  '200': $neutral-color-200,
  '100': $neutral-color-100,
  '50': $neutral-color-50,
  '0': $neutral-color-0,
) !default;

// blues
$blue-800:  #030830 !default;
$blue-700: #182587 !default;
$blue-600: #1237b5 !default;
$blue-500: map-get($primary-colors, 'blue') !default; //#0851DF
$blue-400: #2371f1 !default;
$blue-300: #4992fd !default;
$blue-200: #7cb4ff !default;
$blue-100: #b9d9ff !default;
$blue-50: #ddecff !default;

// stylelint-disable-next-line scss/dollar-variable-default
$blues: (
  '800': $blue-800,
  '700': $blue-700,
  '600': $blue-600,
  '500': $blue-500,
  '400': $blue-400,
  '300': $blue-300,
  '200': $blue-200,
  '100': $blue-100,
  '50': $blue-50,
) !default;

// teals
$teal-700: #185987 !default;
$teal-600: #1576b2 !default;
$teal-500: #1194da !default;
$teal-400: map-get($secondary-colors, 'teal') !default; //#10B2FB
$teal-300: #3ac6ff !default;
$teal-200: #72daff !default;
$teal-100: #b4eeff !default;
$teal-50: #daf6ff !default;

// stylelint-disable-next-line scss/dollar-variable-default
$teals: (
  '700': $teal-700,
  '600': $teal-600,
  '500': $teal-500,
  '400': $teal-400,
  '300': $teal-300,
  '200': $teal-200,
  '100': $teal-100,
  '50': $teal-50,
) !default;

// purples
$purple-700: #3f1887 !default;
$purple-600: #5123b3 !default;
$purple-500: #6434dc !default;
$purple-400: map-get($secondary-colors, 'purple') !default; //#7C4DFF
$purple-300: #8b6dff !default;
$purple-200: #a999ff !default;
$purple-100: #d4ceff !default;
$purple-50: #e9e7ff !default;

// stylelint-disable-next-line scss/dollar-variable-default
$purples: (
  '700': $purple-700,
  '600': $purple-600,
  '500': $purple-500,
  '400': $purple-400,
  '300': $purple-300,
  '200': $purple-200,
  '100': $purple-100,
  '50': $purple-50,
) !default;

// reds
$red-700: #871313 !default;
$red-600: #b32013 !default;
$red-500: #dc3616 !default;
$red-400: map-get($secondary-colors, 'red') !default; //#FF541E
$red-300: #ff6e46 !default;
$red-200: #ff947b !default;
$red-100: #ffc6bb !default;
$red-50: #ffe1de !default;

// stylelint-disable-next-line scss/dollar-variable-default
$reds: (
  '700': $red-700,
  '600': $red-600,
  '500': $red-500,
  '400': $red-400,
  '300': $red-300,
  '200': $red-200,
  '100': $red-100,
  '50': $red-50,
) !default;

// yellows
$yellow-700: #ff8800 !default;
$yellow-600: #ff9c02 !default;
$yellow-500: #ffb005 !default;
$yellow-400: map-get($secondary-colors, 'yellow') !default; //#FFC107
$yellow-300: #fed726 !default;
$yellow-200: #fee961 !default;
$yellow-100: #fdf5ac !default;
$yellow-50: #fdf9d4 !default;

// stylelint-disable-next-line scss/dollar-variable-default
$yellows: (
  '700': $yellow-700,
  '600': $yellow-600,
  '500': $yellow-500,
  '400': $yellow-400,
  '300': $yellow-300,
  '200': $yellow-200,
  '100': $yellow-100,
  '50': $yellow-50,
) !default;

// greens
$green-700: #006117 !default;
$green-600: #00882f !default;
$green-500: #00ab50 !default;
$green-400: #00c670 !default;
$green-300: map-get($secondary-colors, 'green') !default; //#16D892
$green-200: #50e8bb !default;
$green-100: #9ff3de !default;
$green-50: #cdf8ee !default;

// stylelint-disable-next-line scss/dollar-variable-default
$greens: (
  '700': $green-700,
  '600': $green-600,
  '500': $green-500,
  '400': $green-400,
  '300': $green-300,
  '200': $green-200,
  '100': $green-100,
  '50': $green-50,
) !default;

// Borders
$border-width: 1px !default;
$border-width-bold: 2px !default;
$border-color: $neutral-color-100 !default;

$border-width-focus-inner: 2px !default;
$border-color-focus-inner: map-get($teals, '300') !default;
$border-width-focus-outer: 6px !default;
$border-color-focus-outer: map-get($teals, '100') !default;

// Spacers
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer-base: 4px !default;
$spacer-xsmall: $spacer-base * 0.5 !default; // 2px
$spacer-small: $spacer-base !default; // 4px
$spacer-medium: $spacer-base * 2 !default; // 8px
$spacer-large: $spacer-base * 3 !default; // 12px
$spacer-xlarge: $spacer-base * 4 !default; // 16px
$spacer-2xlarge: $spacer-base * 5 !default; // 20px
$spacer_5xlarge: $spacer-base * 8 !default; // 32px
$spacer_7xlarge: $spacer-base * 10 !default; // 40px
$spacer_8xlarge: $spacer-base * 11 !default; // 44px
$spacer_9xlarge: $spacer-base * 12 !default; // 48px

// stylelint-disable-next-line scss/dollar-variable-default
$spacers: (
  'base': $spacer-base,
  'xsmall': $spacer-xsmall,
  'small': $spacer-small,
  'medium': $spacer-medium,
  'large': $spacer-large,
  'xlarge': $spacer-xlarge,
  '2xlarge': $spacer-2xlarge,
  '5xlarge': $spacer-5xlarge,
  '7xlarge': $spacer-7xlarge,
  '8xlarge': $spacer-8xlarge,
  '9xlarge': $spacer-9xlarge,
) !default;

// Typography

$link-color: $primary-blue !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

$font-family-base: 'Muli', 'Helvetica', 'Arial', sans-serif;
$font-family-mono: 'Roboto Mono', 'Courier', monospace;

// $font-weight-lighter:         200 !default;    No use case yet
// $font-weight-light:           300 !default;    No use case yet
$font-weight-regular: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-xbold: 800 !default;
$font-weight-base: $font-weight-regular !default;

$font-size-xsmall: 12px !default;
$font-size-code: $font-size-xsmall !default;
$font-size-small: 14px !default;
$font-size-medium: 16px !default;
$font-size-large: 20px !default;
$font-size-xlarge: $font-size-xsmall * 2 !default; // 24px
$font-size-2xlarge: $font-size-small * 2 !default; // 28px
$font-size-3xlarge: $font-size-medium * 2 !default; // 32px
$font-size-base: $font-size-medium !default;

$line-height-xsmall: 16px !default;
$line-height-small: 20px !default;
$line-height-code: $line-height-small !default;
$line-height-medium: $line-height-small !default;
$line-height-large: 28px !default;
$line-height-xlarge: $line-height-xsmall * 2 !default; // 32px
$line-height-2xlarge: 36px !default;
$line-height-3xlarge: 44px !default;
$line-height-base: $line-height-medium !default;

// Miscellaneous
$z-index-absolute: 99;
$z-index-relative: 1;

// Components
$comp-base-height: 40px;
$comp-background-color: #f7fafc;

// Button
$btn-border-radius: 24px;

// View Port Widths
$small: 600px;
$medium: 900px;
$large: 1280px;
