@import 'src/styles/variables';

.side-panel {
    &__panel-wrapper {
        position: fixed;
        z-index: 3;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        &__overlay {
          position: fixed;
          margin-left:  -100%;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0.7;
        }
        &--scroll-lock {
          overflow: hidden;
        }
      }
    height: 100% !important;
    display: flex;
    width: 63%;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
    top: 0;
    position: fixed;
    flex-direction: column;
    border-right: 1px solid;
    border-radius: 0;
    background-color: rgb(255, 255, 255);
    transition: width 450ms, opacity 100ms;

  &.hidden {
    opacity: 0;
    overflow: hidden;
    width: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 25px;
    border-bottom: 1px solid $neutral-color-100;
  }

  &__title {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    color: $primary-black;
  }

  &__close {
    cursor: pointer;
  }

  &__content {
    flex: 1 1 auto;
    padding: 20px;
    overflow: auto;
  }

  &__footer {
    flex-shrink: 0;
    border-top: 1px solid $neutral-color-100;
    background-color: #FAFAFA;

    padding: 20px;
  }

}

