@import '../../styles/variables';

$width-modal-field: 232px;

.channel-languages {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid $neutral-color-100;

  &__card {
    margin-top: $spacer_5xlarge;
    margin-left: $spacer_7xlarge;

    &__wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__header {
      font-size: $font-size-medium;
      font-weight: $font-weight-bold;
      padding-bottom: $spacer-medium;
    }

    &__subheader {
      font-size: $font-size-small;
      color: $neutral-color-600;
    }

    &__container {
      margin-top: $spacer-xlarge;
      display: flex;
      flex-wrap: wrap;
      font-size: $font-size-small;

      &__tabs {
        display: inline-block;
        flex-wrap: wrap;
        margin-bottom: $spacer-large;
        padding: $spacer-large;
        background-color: $neutral-color-50;
        color: $primary-black;
        border-radius: 5px;
        padding-top: $spacer-medium;
        padding-bottom: $spacer-medium;
        padding-left: $spacer-large;
        font-weight: $font-weight-bold;
        font-size: $font-size-small;

        &--default {
          color: $neutral-color-600;
          padding-left: $spacer-small;
          font-weight: $font-weight-regular;
        }
      }

      &__tabs--spacer {
        padding-left: $spacer-large;
      }
    }

    &__link {
      font-size: $font-size-small;
      color: $primary-blue;
    }
  }

  &__modal {
    min-height: 500px;
    overflow: auto;

    &__value {
      margin-top: $spacer-2xlarge;
      height: 300px;
      overflow: auto;

      &__actions--set-default {
        float: right;
        cursor: pointer;
        visibility: hidden;
        color: $link-color;
      }

      &__actions--delete {
        float: right;
        cursor: pointer;
        visibility: hidden;
        padding-left: 20px;
      }

      &__values {
        border: 1px solid $neutral-color-200;
        margin-bottom: -1px;
        padding: 10px;

        &--not-default {
          border: 1px solid $neutral-color-200;
          margin-bottom: -1px;
          padding: $spacer-large;
          &:hover {
            background-color: $neutral-color-50;
            > .channel-languages__modal__value__actions--set-default {
              visibility: visible;
            }
            > .channel-languages__modal__value__actions--delete {
              visibility: visible;
            }
          }
        }
      }
    }

    &__warning-banner {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 56px;
      background-color: $yellow-50;
      margin-bottom: $spacer-large * 2;

      &__icon {
        height: 40px;
        width: 40px;
        padding-left: $spacer-medium * 2;
      }

      &__text {
        text-align: left;
        margin-left: $spacer-medium * 2;
        font-size: $font-size-small;
        color: $neutral-color-900;
      }
    }

    &__description {
      margin-bottom: $spacer-large * 2;
    }

    &__select {
      .flight-text-input__icon-trailing {
        right: $spacer-xlarge;
      }
    }
  }

  &__error {
    color: $red-700;
  }

  .flight-text-input__input {
    overflow: hidden;
    height: 40px;
    width: 100%;
  }

  .flight-dropdown__dropdown {
    position: fixed;
    top: 45%;
    z-index: 105;
    width: $width-modal-field !important;
  }

  .flight-modal-wrapper .flight-modal--small {
    max-width: 501px;
  }

  .flight-modal__footer {
    padding: 0px !important;
  }
}
