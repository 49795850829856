@import 'styles/_variables';

.datasource-info {
  &__go-back {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 25px;

    &__link {
      font-size: $font-size-small;
      color: $blue-500;

      &:hover,
      &:active {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &__header {
    display: flex;
    margin-left: 40px;
    margin-right: 40px;

    &__image {
      height: 48px;
      width: 48px;
    }

    &__content {
      width: 90%;
      margin: 0 20px;

      &__name {
        color: $primary-black;
        font-size: 24px;
      }

      &__text {
        color: $neutral-color-700;
        font-size: 14px;
        margin-top: 20px;
      }

      &__links {
        margin-top: 15px;
        margin-bottom: 8px;

        &__link-button {
          margin-right: 50px;
        }

        > .datasource-info__header__content__links__link-button:first-of-type {
          padding-left: 0;

          & span.flight-button__label {
            padding-left: 0;
          }
        }
      }
    }
  }

  &__snackbar {
    position: relative !important;
    height: 100% !important;
    width: 97% !important;
    box-shadow: none !important;
    margin-bottom: $spacer-xlarge !important;
    animation: 0.5s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
  }

  &__no-ctx-attr-email-link {
    padding: 0 !important;
    margin: 0 !important;
  }

  &__mappings {
    margin-left: 40px;
    margin-right: 40px;
  }

  &__num-datapoints {
    display: flex;
    align-items: center;

    &__text {
      font-size: $font-size-medium;
      font-weight: $font-weight-bold;
      color: $primary-black;
      margin-right: 10px;
    }
  }

  &__icon {
    width: 17px;
    height: 17px;
  }

  &__table {
    margin: 40px 0;
    max-height: 500px;
    border: 1px solid $neutral-color-200;
    overflow-y: auto;

    .flight-table {
      .flight-table-header {
        font-weight: $font-weight-regular;
        color: $neutral-color-700;
        background-color: $neutral-color-50;
        border-bottom: 1px solid $neutral-color-200;
      }
    }

    &__name {
      display: flex;

      &__icon {
        margin-right: 20px;
      }

      &__attribute-name {
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        color: $primary-black;
        margin-right: 5px;
      }

      &__data-type {
        font-size: $font-size-xsmall;
        color: $neutral-color-700;
        text-transform: capitalize;
      }
    }

    &__category {
      font-size: $font-size-small;
      color: $blue-500;
      margin-right: 5px;
      cursor: pointer;
    }

    &__attribute {
      font-size: $font-size-small;
      font-weight: 700;
      color: $blue-500;
      cursor: pointer;

      &:hover,
      &:active {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__mapped-attribute {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $font-size-xsmall;
      font-weight: $font-weight-regular;
      color: $primary-blue;
      max-width: 250px;
      padding: 5px 10px;
      border: 1px solid $neutral-color-100;
      border-radius: 5px;

      &__name {
        font-weight: $font-weight-bold;
      }

      &__clear-icon {
        cursor: pointer;
      }
    }
  }

  .context-attributes {
    padding-top: 2%;
    animation: 0.5s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
  }

  .context-attributes__table {
    margin-top: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
