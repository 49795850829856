@import '../../../styles/variables';

.update-template-details {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 $spacer-2xlarge;

  .flight-modal-wrapper .flight-modal {
    .flight-modal__header {
      border-radius: 9px 9px 0 0;
      background-color: $blue-400;
      color: $primary-white;
      padding: $spacer-xlarge;
      box-shadow: none;

      svg {
        width: 28px;
        height: 28px;
        fill: $primary-white;
      }
    }

    .flight-modal__content {
      min-height: 0;
      padding: $spacer-7xlarge $spacer-2xlarge $spacer-2xlarge;
    }

    .flight-modal__footer {
      padding: 0;
    }
  }

  &__custom-template {
    display: flex;
    flex-direction: column;
    flex: 0 0 280px;
    width: 280px;
  }

  &__template-details {
    flex: 1;
  }

  &__column-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    margin: 0 (-$spacer-2xlarge);
    border-top: 1px solid $neutral-color-100;
    border-bottom: 1px solid $neutral-color-100;
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    color: $neutral-color-800;
  }

  &__template-list {
    max-height: 466px;
    margin: 0;
    padding: 0 24px 0 0;
    overflow-y: auto;

    &__category {
      max-width: 100%;
      margin: 0;
      font-size: 10px;
      line-height: 1;
      font-weight: $font-weight-regular;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__name {
      width: 100%;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 48px;
      padding: 0 12px;
      font-weight: $font-weight-bold;
      color: $primary-black;
      background: none;
      border: 0;
      border-radius: 10px;

      &--is-selected {
        background-color: $neutral-color-50;
      }

      &:focus {
        outline: 0;
      }
    }

    li {
      list-style: none;
      margin: $spacer-medium 0;
    }
  }

  &__form {
    display: flex;
    column-gap: $spacer-double;
    padding: 36px 12px;

    &__column {
      flex: 1;
    }
  }

  &__field {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }

    label {
      margin-bottom: $spacer-medium;
      font-size: $font-size-small;
      font-weight: $font-weight-bold;
      color: $neutral-color-600;
    }

    .flight-text-input-wrapper {
      width: 100%;
      box-sizing: content-box;

      input {
        width: 100%;
      }
    }
  }

  .flight-label-search__trigger {
    font-size: $font-size-small;
  }

  .flight-text-area {
    padding: 0;

    &__textarea {
      margin: 0;
      width: 100%;
      border: 1px solid #d8d8d8;
    }

    &__footer {
      margin: 0;
      padding: 4px;
      width: 100%;
      text-align: right;
    }
  }

  &__reset-template {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $neutral-color-500;

    button {
      margin-left: $spacer;
    }
  }

  &__modal-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: $spacer-large;
    margin: $spacer-7xlarge -1 * $spacer-2xlarge 0;
    padding: $spacer-2xlarge $spacer-2xlarge 0;
    border-top: 1px solid $neutral-color-200;
  }

  &__select__dropdown__portal {
    .flight-dropdown__dropdown {
      width: 100%;
      padding: 0;

      .flight-select__dropdown__option:first-child {
        height: 42px;
      }

      .flight-select__dropdown__option:nth-child(2) {
        border-top: 1px solid $neutral-color-200;
      }
    }
  }
}
