@import '../../../styles/variables';

.idp-modal {
  &__content {
    padding-bottom: $spacer-2xlarge;
    padding: $spacer-2xlarge;

    &__content-header {
      padding-bottom: $spacer-2xlarge;
      &__header-logo {
        margin-left: $spacer-2xlarge * 1.5;
        height: 24px;
        width: 24px;
      }
      &__header-text {
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        padding-left: $spacer-large;
      }
    }

    &__toggle {
      margin-inline-start: $spacer-2xlarge * 14;
    }
    &__text-field-container {
      padding-bottom: $spacer-2xlarge;
      &__text-field {
        padding-bottom: $spacer-xlarge;
        padding-top: $spacer-xlarge;
        padding-left: $spacer-2xlarge;
        padding-bottom: $spacer-2xlarge;
        *,
        ::after,
        ::before {
          box-sizing: unset;
        }
      }
    }

    &__upload-box {
      line-height: 100px;
      border: 1px dashed rgba(5, 19, 54, 0.2);
      margin-bottom: 20px;
      padding-left: 100px;
      &__text {
        color: $link-color;
        cursor: pointer;
      }
      &__img {
        padding-right: 10px;
      }
      &__allowed-text {
        margin-top: -15px;
        color: rgba(5, 19, 54, 0.6);
        font-size: $font-size-xsmall;
      }
    }

    &__text-block {
      padding-bottom: $spacer-2xlarge * 4;
      &__display-uploaded {
        padding: 11px;
        width: 303px;
        height: 40px;
        color: $neutral-color-500;
        background-color: $neutral-color-50;
        &__img {
          cursor: pointer;
          float: right;
        }
      }
      &__delete {
        cursor: pointer;
        margin-top: 10px;
        margin-left: $spacer-xlarge;
      }
    }
  }

  .flight-modal-wrapper {
    display: grid;
  }

  .flight-modal{
    margin: auto;
  }

  .flight-modal-wrapper .flight-modal--small {
    max-width: 465px !important;
    max-height: 850px !important;
  }

  .flight-modal-wrapper .flight-modal .flight-modal__content {
    overflow: hidden !important;
    max-height: 700px !important;
  }

  .flight-modal__footer {
    padding: 0px !important;
  }
}
