@import '../../styles/variables';

.image-upload {
  position: relative;
  display: flex;
  column-gap: 15px;

  label.image-upload__styled-file {
    margin-bottom: 0;
  }

  &__styled-file {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 236px;
    height: 133px;
    padding: 12px;
    border-bottom: 1px solid $neutral-color-300;
    border-radius: 5px 5px 0 0;
    background-color: $neutral-color-50;

    p {
      margin: 0;
      font-size: 12px;
      font-weight: $font-weight-regular;
      line-height: 1.3;
      text-align: center;
    }

    svg {
      margin-top: $spacer-large;
    }

    input {
      position: absolute;
      left: -9999px;
      visibility: hidden;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -1px;
    left: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #F2F3F5;
  }

  &__upload-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    color: $neutral-color-500;

    small {
      display: block;
      margin-top: 8px;
    }

    div {
      line-height: 1;
    }

    button {
      width: 100%;
    }
  }

  &__loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, .85);

    svg {
      width: $font-size-3xlarge;
      height: $font-size-3xlarge;
    }
  }
}

