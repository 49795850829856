@import 'styles/_variables';

.context-category {
  .flight-modal-wrapper {
    z-index: 99;
  }
  .flight-modal-wrapper .flight-modal--large {
    max-width: 848px !important;
  }

  .flight-text-input__input {
    width: 100%;
  }

  &__modal {
    &__content {
      height: 380px;
      &__label {
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
      }

      &__text-area {
        min-width: 365px !important;
        width: 365px !important;
        margin-top: -30px;
        margin-bottom: 30px;
      }

      &__icon-url {
        margin-left: 10px;
        &__icon-preview {
          margin-left: 10px;
          width: 41px;
          height: 41px;
          border: 1px solid $neutral-color-100;
          &__img {
            width: 38px;
            height: 39px;
          }
        }
      }
      &__ctx-id {
        width: 363px;
        height: 33px;
        border-radius: 2px;
        margin-top: 5px;
        border-radius: 5px;
        border: 0.5px solid $border-color;
        &__icon {
          cursor: pointer;
          padding: 5px;
          margin-top: 5px;
          margin-bottom: 5px;
          width: 24px;
          height: 24px;
          &--disabled {
            pointer-events: none;
          }
        }
        &__text {
          color: $neutral-color-700;
          margin-top: 6px;
          position: absolute;
          max-width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__label {
          margin-top: 20px;
        }
        &__field {
          margin-top: 10px;
        }
      }
    }
  }
}
