@import '../../../styles/variables';

.manage-templates {
  > .page-header {
    margin-left: 0px;
  }
  &__description {
    &--bold {
      font-weight: $font-weight-bold;
    }
    color: $neutral-color-700;
    font-size: $font-size-small;
  }

  &__modal {
    > .flight-modal__content {
      min-height: 300px;
    }
    
    &__content {
      &__values {
        border: 1px solid $neutral-color-200;
        margin-bottom: -1px;
        padding: 10px;
      }
    }
  }

  &__container {
    margin-top: $spacer-xlarge * 2;
    &__link {
      color: $primary-blue;
      cursor: pointer;
    }
  }

  &__table {
    margin-right: 50px;
    border: 1px solid $neutral-color-200;
    max-height: 400px;
    overflow: auto;
    .flight-table-header {
      border-bottom: 1px solid $neutral-color-200;
      background-color: $neutral-color-50;
      font-weight: $font-weight-regular;
      color: $neutral-color-700;
    }
  }
}

.flight-modal .flight-modal__content {
  margin-bottom: $spacer-large !important;
}
