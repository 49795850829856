@import 'styles/_variables';

.ctx-attributes-empty-state {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 120px;

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-right: 40px;

    &__page-header {
      margin-left: -2px;
      &.page-header {
        padding: 0;
        width: 100%;
      }
    }

    &__description {
      font-size: 14px;
      color: $primary-black;
      margin: 20px 0 40px 0;
    }

    &__links {
      > button {
        margin-right: $spacer-2xlarge;
      }
    }
  }
}
