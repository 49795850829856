@import 'styles/_variables';
@import 'styles/_fonts';

.clientSecret {
  &--label {
    display: block;
    @extend .flight-font;
    font-size: $font-size-small;
    color: $neutral-color-600;
    padding-left: 6px;
  }
  &--value {
    padding-left: 6px;
  }
  &--edit {
    width: 20px;
    height: 20px;
    fill: $neutral-color-600;
    margin-left: 6px;
    cursor: pointer;
    &:hover {
      fill: map-get($primary-colors, base);
    }
  }
  &--field {
    display: inline-block;
  }
}
