@import '../../styles/variables';

.connected-datasources {
  border-left: 1px solid $neutral-color-100;
  overflow: auto;
  height: 100%;
  padding: 40px;

  &--datasource-info {
    padding-left: 0px;
    padding-right: 0px;
  }

  &__title {
    padding: 0;
    margin-left: 0;
  }

  &__search-bar {
    margin-top: 50px;
    margin-bottom: 40px;

    .search-bar__icon {
      top: 40%;
    }
  }

  &__list {
    animation: 0.5s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
  }

  &__card-wrapper {
    &.card {
      max-width: 100%;
      border-bottom: none;
      border-radius: 0;
    }

    &.card:last-of-type {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    animation: 0.5s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
  }

  &__card {
    display: flex;

    &__image {
      height: 48px;
      width: 48px;
      margin-top: 5px;
    }

    &__content {
      padding-left: 30px;

      &__header {
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;

        &__title {
          background: none;
          border: none;
          padding: 0;
          font-weight: 700;
          color: $primary-blue;
        }
      }

      &__subheader {
        font-size: $font-size-small;
        color: $neutral-color-600;
        margin-top: 15px;
      }

      &__viewbox-loader {
        margin-left: -48px;
      }

      &__description {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        font-size: $font-size-small;
        color: $neutral-color-600;
      }
    }
  }

  &__snackbar {
    position: relative !important;
    height: 48px !important;
    width: 97% !important;
    box-shadow: none !important;
    margin: 0 !important;
    animation: 0.5s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
