@import '../../../styles/variables';

.push-limits {
  &__layout {
    padding: 20px;
  }

  &__footer {
    float: right;
  }
  
  &__header {
    display: inline-block;
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    &--infoIcon {
      margin-right: $spacer-medium;
    }
  }
  &__description {
    font-size: $font-size-small;
    padding-top: $spacer-medium;
    color: $neutral-color-600;
  }
  &__toggle {
    float: right;
  }
  &__link {
    font-size: $font-size-small;
    color: $primary-blue;
  }
  &__btn {
    float: right;
    padding-bottom: $spacer-2xlarge;
  }
  &__description {
    &--infoIcon {
      width: 20px;
      height: 20px;
      margin-left: $spacer-medium;
      fill: $blue-500;
    }
  }
  &__notification-area{
    display: flex;
    width: 100%;
    margin-top: $spacer-2xlarge;
    gap: $spacer-2xlarge;

    .flight-number-input__input {
      width: 100%;
      height: 41px;
    }
  }
}
