@import 'styles/_variables';

.template-view {
  position: relative;

  &-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    svg {
      width: 50%;
      #fly {
        transform: translate3d(-631px, -99px, 0px);
      }
    }
  }

  &__table {
    .bottom-pagination {
      min-width: 524px;
      height: 42px;
      background: $neutral-color-50;
      margin-top: 0;
      padding-top: 6px;
      padding-bottom: 6px;
      border-radius: 0 0 5px 5px;
    }

    .flight-table-pagination {
      background-color: $neutral-color-50;

      .flight-number-input__input {
        background-color: white;
        height: 100%;
        padding: 6px 0px;
      }

      .flight-select__dropdown__trigger__button {
        background-color: white;
        padding: 6px 0px;
        height: 30px;
        border-radius: 5px 5px 0 0;
        svg {
          margin: 0;
        }
      }

      .flight-button--without-text {
        background: unset;
        padding: 0;
      }

      .flight-select__border {
        top: unset;
      }

      &__page-control {
        margin-left: 10px;
      }
    }

    .flight-table {
      tr {
        height: 42px;
        width: auto;
      }

      thead th,
      tbody td {
        &:first-child {
          padding-left: 5px;
        }
      }

      .flight-table-header {
        height: 42px;
        padding: 0 6px 0 6px;
        white-space: nowrap;
        background-color: $neutral-color-50;
        width: 100%;
        min-width: 120px;
        &:first-child {
          border-radius: 5px 0 0 0;
          padding-right: 8px;
          padding-left: 16px;
          min-width: unset;
        }
        &:nth-child(6) {
          border-radius: 0 5px 0 0;
        }
        &:nth-child(2) {
          padding-left: 8px;
          width: 43%;
          min-width: 135px;
        }
        &:nth-child(3),
        &:nth-child(4) {
          width: 18%;
        }
        &:nth-child(5) {
          min-width: 70px;
        }
        &:nth-child(5),
        &:nth-child(6) {
          width: 10.5%;
        }

        @media (max-width: 903px) {
          &:nth-child(2) {
            width: 30%;
          }

          &:nth-child(n + 2) + :not(:last-child) {
            width: 17.5%;
          }
        }

        @media (min-width: 2057px) {
          &:nth-child(2) {
            width: 42%;
          }

          &:nth-child(n + 2) + :not(:last-child) {
            width: 14.5%;
          }
        }
      }

      .flight-table-header__sort-icon {
        margin-left: 2px;
      }

      thead {
        z-index: 99;
        display: block !important;

        tr {
          display: block;
        }
      }

      tbody {
        display: block !important;
        width: 100%;
        overflow: auto;
        height: calc(100vh - 369px); // 319px is the height of all of the other components on the page
        min-height: 42px;
        max-height: 50%;
      }

      .flight-table-row {
        &__cell {
          max-width: 0;
          width: auto;
          height: 42px;
          padding: 0 6px 0 6px;
          min-width: 120px;

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:first-child {
            padding-right: 8px;
            padding-left: 16px;
            max-width: unset;
            min-width: unset;
          }

          &:nth-child(2) {
            padding-left: 8px;
            width: 43%;
            min-width: 135px;
          }

          &:nth-child(3),
          &:nth-child(4) {
            width: 18%;
          }

          &:nth-child(5) {
            min-width: 70px;
          }

          &:nth-child(5),
          &:nth-child(6) {
            width: 10.5%;
          }

          @media (max-width: 903px) {
            &:nth-child(2) {
              width: 30%;
            }

            &:nth-child(n + 2) + :not(:last-child) {
              width: 17.5%;
            }
          }

          @media (min-width: 2057px) {
            &:nth-child(2) {
              width: 42%;
            }

            &:nth-child(n + 2) + :not(:last-child) {
              width: 14.5%;
            }
          }
        }

        &:not(.flight-table-row--select-effect) {
          opacity: 0.66;
          cursor: not-allowed;
        }

        &:hover {
          background-color: #f6faff;
        }
      }
    }

    &__type {
      font-size: $font-size-small;
      font-weight: $font-weight-bold;
      &--flybits {
        color: $teal-700;
      }

      &--custom {
        color: $yellow-700;
      }
    }

    &__options {
      display: flex;
      justify-content: center;
      .flight-button--secondary {
        background: none;
        border: none;
        color: #b4b8c3;
        box-shadow: none;
        padding: 0;
        height: 32px;
        width: 32px;
        svg {
          fill: map-get($neutral-colors, '300');
          height: 18px;
          width: 18px;
        }
        &:enabled {
          &:hover {
            background-color: $blue-300;
            svg {
              fill: white;
              height: 15px;
            }
          }
        }
      }
    }
  }
}
