@import '../../../styles/variables';

// Drag and Drop
#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: copy;
}

#label-file-upload.drag-active {
  background-color: #cbd8e5;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

// Component
.upload-template {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  padding: 0 $spacer-2xlarge * 1.5;

  &__progress-modal {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: progress;
    z-index: 90;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    &.error {
      cursor: inherit;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;

      position: relative;
      width: 376px;
      height: 288px;
      border-radius: 15px;

      border-radius: 15px;
      text-align: center;
      background-color: #ffffff;
    }
    &-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      color: white;
      height: 76px;
      width: 100%;
      font-size: 20px;
      line-height: 25px;
      font-style: normal;
      font-weight: 100;
      border-radius: 15px 15px 0 0;
      background: map-get($blues, '500');
      &.error {
        background: map-get($reds, '500');
      }
    }
    &-footer {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .flight-button {
        font-weight: 400;
      }
    }
    &-content {
      display: flex;
      align-items: center;
      text-align: center;
      flex-direction: column;
      width: 100%;
      border-bottom: 1px solid #dbdbdb;
      box-shadow: inset 0px -4px 5px rgba(0, 0, 0, 0.05);
      padding: 24px 36px;
      progress[value] {
        /* Reset the default appearance */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        /* Get rid of default border in Firefox. */
        border: none;

        border-radius: 6px;
        width: 100%;
        margin-bottom: 24px;
        height: 12px;
      }
      progress[value]::-webkit-progress-bar {
        background-color: #f2f3f5;
        border-radius: 6px;
      }
      progress[value]::-webkit-progress-value {
        background-color: map-get($blues, '500');
        border-radius: 6px 0 0 6px;
      }
      progress[value='100']::-webkit-progress-value {
        background-color: map-get($blues, '500');
        border-radius: 6px;
      }
      p {
        font-weight: 100;
        font-size: 16px;
        line-height: 150%;
        color: #1e2b4a;
      }
      ul {
        text-align: start;
        padding-inline-start: 0;
      }
    }
  }
  &-drop-area {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    text-align: center;
    background-color: #fbfbfb;
    border: 1px solid map-get($neutral-colors, '100');
    border-radius: 5px;
    flex-basis: calc(100% - 370px);
    line-height: 2.5rem;
    font-weight: 100;
    &-content {
      color: map-get($neutral-colors, '600');
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-evenly;
      flex-wrap: wrap;
      height: 50%;
    }
    button {
      font-weight: normal;
      margin-top: 40px;
      svg {
        transform: rotate(180deg);
      }
    }
  }
  &-info-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 26px;
    opacity: 0.6;
    width: 340px;
    &-item {
      ol {
        counter-reset: list-counter;
        list-style: none;
        margin: 0;
        margin-block-start: 0;
        padding-inline-start: 0;
      }
      li {
        counter-increment: list-counter;
        padding-bottom: 12px;
        &:last-child {
          padding-bottom: 0;
        }
      }
      li::before {
        content: counter(list-counter);
        color: white;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #4e66a5;
        border-radius: 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        margin-right: 6px;
      }
      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
        line-height: 25px;
        display: flex;
        align-items: center;

        color: #1e2b4a;

        /* Inside auto layout */

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }
      p,
      li {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        color: #1e2b4a;
        margin: 0;
      }
      /* container-import-info */

      box-sizing: border-box;

      /* Auto layout */

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 16px 16px 24px;
      gap: 8px;

      background: #ffffff;
      /* N200 */

      border: 1px solid #cdd0d7;
      border-radius: 5px;

      /* Inside auto layout */

      flex: none;
      order: 2;
      flex-grow: 0;
    }
  }
}
