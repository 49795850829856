@import '../../styles/variables';

.export-template-modal {
  &__footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    &__template-amount {
      margin-right: auto;
    }
  }

  .flight-modal-wrapper {
    display: grid;
  }

  .flight-modal{
    margin: auto;

    &--large {
      max-width: 1184px !important;
      transform: none;
      top: $spacer-double;
    }

    &__header {
      text-align: center;

      button {
        display: none;
      }
    }

    &__content {
      margin-bottom: 0 !important;
      padding: 0 !important;
      max-height: calc(100vh - 192px) !important;
      min-height: 600px;
      height: auto;
    }

    &__footer {
      border-top: 1px solid #dee2e6;
      padding: 12px 16px !important;
    }
  }
}