@import 'styles/_variables';

.apns-settings-modal {
  &__modal {
    padding: 10px;
  }

  &__manage-modal {
    max-width: 600px !important;
    padding: 10px;
  }

  &__certificate-btn {
    float: right;
    color: $red-400;
    font-weight: 400;

    &:hover {
      color: $red-500 !important;
    }
  }

  &__certificate-container {
    width: 100%;
  }

  &__certificate-name {
    float: left;
  }

  &__certificate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px;
    border: 1px solid $neutral-color-200;
  }

  &__manage-text,
  &__certificate-title {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: rgba(5, 19, 54, 0.6);
    border-radius: 4px;
  }

  &__certificate-req {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: rgba(5, 19, 54, 0.6);
    border-radius: 4px;
  }

  &__check {
    float: left;
    margin-right: 5px;
  }

  &__icon {
    cursor: pointer;
    margin-left: 20px;
    width: 18px;
    height: 18px;
  }

  &__active {
    cursor: pointer;
  }

  &__error {
    color: $secondary-red;
  }

  &__table {
    margin: 25px 0;
    border: 1px solid $neutral-color-200;

    &__certificate-name {
      font-weight: 700;
      font-size: 14px;
      color: $primary-black;
    }

    &__environment {
      font-weight: 400;
      font-size: 12px;
      color: $neutral-color-700;
      margin-top: 3px;
    }

    &__delete-icon {
      cursor: pointer;
    }

    &__status {
      font-weight: bold;
      font-size: 12px;
      color: $red-600;
      background-color: $red-100;
      padding: 5px;

      &:hover {
        cursor: pointer;
      }

      &.active {
        color: $green-600;
        background-color: $green-50;
        padding: 5px 10px;
      }
    }
  }

  &__status-dropdown {
    position: relative;
    z-index: 0;

    > .flight-button {
      &__label {
        color: $neutral-color-300;
      }
    }

    &.active {
      > .flight-button .flight-button__label {
        color: $green-500;
      }
    }
  }

  .dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    border: 1px dashed $neutral-color-300;
    cursor: pointer;

    &__text {
      font-size: 14px;
      font-weight: 700;
      color: $primary-blue;
      margin-left: 6px;
    }
  }
  .flight-text-input__input {
    height: 45px!important;
  }
}

.token {
  .apns-settings-modal {
    &__certificate-req {
      margin-top: 5px;
    }
  }

  .flight-modal-wrapper {
    .flight-modal--small {
      max-width: 425px !important;
    }
  }
}
