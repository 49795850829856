@import '../../../styles/variables';

// Component
.resolve-conflicts-base {
  width: 100%;
  position: relative;
  &__top {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    &-conflicts {
      background: map-get($reds, '50');
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      > button {
        margin-left: 24px;
        border: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: map-get($neutral-colors, '800');
        box-shadow: none;
        background: rgba(255, 255, 255, 0.5);
        &:focus:enabled,
        &.active,
        &:hover:enabled {
          color: map-get($neutral-colors, '800');
          background-color: rgba(255, 255, 255, 0.5);
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
          svg {
            fill: map-get($reds, '500');
          }
        }
        svg {
          fill: map-get($reds, '500');
        }
      }
    }
    &-info {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 12px 32px;
      background: #ffffff;
      height: 50px;
      .info {
        display: inline-flex;
        align-items: center;
        margin-left: 24px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: map-get($blues, '400');
        svg {
          fill: #5f95ff;
          width: 14px;
          height: 14px;
          margin-left: $spacer-base;
        }
      }
    }
  }
  &__bottom {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: -30px;
  }
  &__content {
    display: flex;
    height: 100%;
    max-height: 416px;
    width: 100%;
  }
}
