@import 'styles/_variables';

.event-trigger {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 600px;
  padding: 50px 0 24px 0;
  border-bottom: 1px solid $neutral-color-200;

  &__event-icon {
    margin-top: 6px;
  }

  &__event-text {
    max-width: 236px;
    font-size: $font-size-small;
  }

  &__rule {
    display: flex;
    justify-content: space-between;
    width: 280px;
    border: 1px solid $neutral-color-100;
    padding: 5px 10px;

    &__info {
      display: flex;
      flex-direction: column;
    }

    &__name {
      font-size: $font-size-xsmall;
      font-weight: $font-weight-bold;
    }

    &__id {
      font-size: $font-size-xsmall;
      color: $neutral-color-600;
    }
  }

  &__clear-icon {
    cursor: pointer;
  }

  &__rule-select {
    width: 280px !important;

    .flight-text-input-wrapper {
      width: 280px !important;
    }

    &.has-selected-rules {
      margin-top: 16px;
    }
  }
}
