@import '../../styles/variables';

.content-templates-page {
  height: 100%;
  border-left: 1px solid $neutral-color-100;
  &__tabstrip {
    &__container {
      justify-content: center;
      display: flex;
      &__tabs {
        padding-top: 46px;
        &.row {
          margin-left: 0;
          margin-right: 0;
          .col {
            padding-left: 0;
            padding-right: 0;
          }
        }
        &__label {
          margin-left: -12px;
          text-decoration: none !important;
        }
      }
    }
  }
  .nav-tabs {
    min-width: 100%;
  }
  .nav-tabs .nav-link {
    border: none !important;
    margin-left: 42px;
    cursor: pointer;
    &.active {
      border-bottom: 4px solid #0851df !important;
      color: #051336;
      font-weight: 700;
      margin-left: 42px;
    }
  }
}
