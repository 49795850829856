@import '../../../styles/variables';

.project-details-card {
  &__error {
    color: red;
  }
  &__name {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    &__edit {
      cursor: pointer;
      margin-left: $spacer-base * 2;
      width: $spacer-base * 4;
      height: $spacer-base * 4;
    }
  }
  &__jdenticon {
    > svg {
      border: 1px solid $neutral-color-200;
    }
  }
  &__status {
    margin-top: $spacer-base * 3;
    &__text {
      color: $success-color;
      background-color: #defcf5;
      font-size: $font-size-small;
      margin-left: 6px;
      margin-right: 4px;
      &--inactive {
        background-color: $red-100;
        color: $red-600;
        font-size: $font-size-small;
      }
    }
    &__action {
      color: $link-color;
      cursor: pointer;
      margin-left: $spacer-base * 4;
      font-size: $font-size-small;
    }
  }
  &__details {
    padding: 25px;
    background-color: rgba(242, 243, 245, 0.5);
    margin-top: $spacer-base * 2;
    margin-left: $spacer-base * 4;
    margin-right: $spacer-base * 4;
    margin-bottom: 40px;
    &__fields {
      padding: 10px;
      color: $neutral-color-600;
      font-size: $font-size-small;
      &--id {
        color: $primary-black;
        & span {
          visibility: hidden;
        }
        &:hover, &:focus {
          text-decoration: underline;
          cursor: pointer;
          > span {
            float: right;
            visibility: visible;
            color: $link-color;
          }
        }
      }
      &--link {
        color: $link-color;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
