@import '../../../styles/variables';

.push-templates {
  height: 100%;
  overflow: hidden;
  &__snack-bar {
    position: relative !important;
    height: 60px !important;
    width: 100% !important;
    box-shadow: none !important;
    z-index: 0 !important;

    .flight-snackbar__content {
      width: 85%;
    }
  }
  .flight-table {
    > tr {
      display: block !important;
    }
    > thead {
      display: block !important;
    }
    > tbody {
      display: block !important;
      overflow: auto !important;
      max-height: 43vh !important;
      width: 100% !important;
    }
  }
  &__table {
    border: 1px solid $neutral-color-200;
    overflow: auto;
    margin-bottom: 60px;

    .flight-table-header {
      width: 100%;
      min-width: 140px;
      border-bottom: 1px solid $neutral-color-200;
      background-color: $neutral-color-50;
      font-weight: $font-weight-regular;
      color: $neutral-color-700;
    }
    .flight-table-row__cell {
      min-width: 140px;
      width: 100%;
    }
  }
  &__btn {
    float: right;
  }
  &__name {
    color: $primary-blue;
    font-weight: $font-weight-bold;
    cursor: pointer;
  }

  &__status {
    .flight-tag {
      cursor: pointer;
      font-size: 12px;
      line-height: 15px;
    }

    &--reserved {
      .flight-tag {
        cursor: default;
      }
    }
  }

  &__delete-modal {
    &__text {
      font-size: $font-size-medium;
      padding-bottom: $spacer-medium;
      margin-bottom: $spacer-2xlarge;
    }
  }
}
