@import '../../styles/variables';

.service-accounts {
  padding: 40px;
  border-left: 1px solid $neutral-color-100;
  overflow: hidden;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__page-header {
    margin-left: 0;
    padding: 0;
  }

  &__search-bar {
    margin-top: 30px;
  }

  &__content {
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $medium) {
      flex-direction: column;
    }
  }
}
