@import '../../../styles/variables';

.subscriptions-card {
  width: 40%;

  .card-body {
    min-height: 600px;
  }
  &__header {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
  }

  &__title {
    margin: 25px 0px;
  }

  &__value {
    font-size: 32px;
    display: inline-block;
  }

  &__total {
    font-size: 16px;
    margin-left: 10px;
    display: inline-block;
  }

  &__table {
    border: 1px solid $neutral-color-200;
    width: 100%;

    .flight-table-row__cell {
      padding: $spacer - 3;

      &:first-child {
        width: 90%;
      }

      &:nth-child(2) {
        width: 10%;
      }
    }
  }

  &__footer {
    font-size: 12px;
    margin-top: 20px;
  }

  &__link {
    color: $primary-blue;
  }

  @media screen and (max-width: $medium) {
    width: 100%;
    margin-bottom: 3%;
  }
}
