@import '../../../styles/variables';

.page-header {
  padding-top: 40px;
  margin-left: 40px;
  width: 40%;

  .large {
    font-size: $font-size-xlarge;
    font-weight: $font-weight-bold;
  }
  .small {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
  }
}
