@import '../../styles/variables';

.api-usage-page {
  border-left: 1px solid $neutral-color-100;
  height: 100%;
  &__content {
    padding: 3%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $medium) {
      flex-direction: column;
    }
  }
}
