.flight-modal__content{
  display: grid;
  .export-download{
    margin: auto;
    *{
      display: block;
      margin: auto;
      text-align: center;
    }

    &__text, &__clickable-text {
      display: inline-block !important;
    }

    &__clickable-text {
      color: #175CFF;
    }
    &__clickable-text:hover {
      cursor: pointer;
    }
  }
}