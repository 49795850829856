@import '../../styles/variables';

.create-or-manage-webhook {
  width: 100%;
  padding: 40px;
  border-left: 1px solid $neutral-color-100;

  .flight-text-input__input {
    width: 100%;
    height: 40px;
  }

  &__form {
    width: calc(100% - 320px);

    &.save-bar-visible {
      margin-bottom: 80px;
    }
  }

  &__field {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    color: $neutral-color-900;
  }

  &__description {
    &.flight-text-area {
      padding: 0;

      .flight-text-area__textarea {
        width: calc(100% - 10px);
      }
    }
  }

  &__request-url {
    display: flex;
    justify-content: space-between;
    width: 600px;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &__details {
      display: flex;
      justify-content: space-between;

      &__name {
        font-size: $font-size-xlarge;
        font-weight: $font-weight-regular;
      }

      &__pen-icon {
        margin: 0 10px;
        cursor: pointer;
      }

      &__date {
        font-size: $font-size-xsmall;
        font-weight: $font-weight-regular;
        color: $neutral-color-400;
      }
    }
  }

  &__request-headers {
    width: 600px;
  }

  &__footer {
    margin-top: 70px;
  }

  &__submit-btn {
    margin-right: 20px;
  }

  &__confirm-navigation-modal {
    &__subheader {
      font-size: $font-size-medium;
      padding-bottom: $spacer-medium;
      margin-bottom: $spacer-2xlarge;
    }

    .flight-modal__footer {
      .modal-footer {
        padding: 0px;
        border-top: none;
      }
    }
  }
}
