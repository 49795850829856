@use "sass:selector";
@import '../../../styles/variables';

.array-fields {
  padding-bottom: $spacer-2xlarge * 2;
  position: relative;
  &__field-array {
    margin-top: -10px;
  }
  &__index-box {
    width: 40px;
    height: 40px;
    background: $neutral-color-50;
    z-index: 2;
    position: absolute;
    margin-top: 0.08px;
    padding: 5px;
    padding-top: 10px;
    padding-left: 15px;
    border: 1px solid $neutral-color-200;

    &--error {
      border: 1px solid $red-500;
    }
  }

  .flight-text-input--error {
    .flight-text-input__input:hover {
      border: 1px solid $red-500 !important;
    }
  }

  &__btn-no-display {
    position: relative;
    width: 0;
    height: 0;
    visibility: hidden;
  }
  &__error {
    color: $red-500;
  }
  &__text-input {
    width: 430px;
  }

  &__input-wrapper {
    position: relative;

    .flight-text-input__input:hover {
      border-color: $neutral-color-200 !important;
    }

    .flight-text-input {
      &__input {
        border: 1px solid $neutral-color-200;
        padding-left: 45px;
        padding-right: 60px;
        font-size: $font-size-small;
        background-color: white;
        border-radius: 0 !important;

        &__clear-icon {
          margin-top: 11px;
          position: absolute;
          cursor: pointer;
          z-index: 2;
          right: 4%;
          visibility: visible;
        }
      }

      &__icon-trailing {
        top: 7px !important;
      }

      &--error {
        margin-bottom: 0.5rem;
      }

      &__border {
        background-color: transparent !important;
      }

      &__icon-trailing {
        right: 38px;
      }
    }
  }

  &__add-field {
    background: none;
    border: none;
    font-size: $font-size-small;
    font-weight: 700;
    color: $primary-blue;
  }
}
