@import '../../styles/variables';

.about-project-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid $neutral-color-100;

  &__content {
    margin-top: 30px;
    margin-left: 40px;
  }
}
