@import 'styles/_variables';

.webhooks-table {
  max-height: 500px;
  margin-top: 40px;
  border: 1px solid $neutral-color-200;
  overflow-y: auto;

  .flight-table {
    .flight-table-header {
      font-weight: $font-weight-regular;
      color: $neutral-color-700;
      background-color: $neutral-color-50;
      border-bottom: 1px solid $neutral-color-200;
    }
  }

  &__name {
    font-size: 14px;
    font-weight: 700;
    color: $primary-blue;
    cursor: pointer;
  }

  &__last-modified {
    font-size: 12px;
    font-weight: 400;
    color: $primary-black;
  }

  &__options {
    .flight-overflow-menu__dropdown-list {
      &__group__option:last-of-type {
        border-top: 1px solid $neutral-color-100;
        color: $secondary-red;

        &:hover {
          color: $primary-white;
          background-color: $secondary-red;
        }
      }
    }
  }

  &__tooltip {
    .flight-tooltip__tooltip {
      max-width: 260px;
    }
  }
}
