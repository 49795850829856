@import 'styles/_variables';

.validation-messages {
  &__validation {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin: 5px 0;
    color: $green-500;

    &.error {
      color: $red-600;
    }
  }

  &__icon {
    margin-right: 8px;
    width: 12px;
  }
}
