@import '../../../styles/variables';
.user-subscriptions {
  &__empty-text {
    text-align: center;
    margin-right: auto;
    color: darkgray;
  }
  &__delete-modal {
    &__text {
      margin-left: 10px;
    }
  }
  &__modal {
    overflow: hidden;
    margin-bottom: 10px;

    &__cancel {
      float: right;
    }
    &__submit {
      margin-left: 15px;
      margin-bottom: 20px;
      float: right;
    }
    &__footer {
      float: right;
    }
  }
  .container {
    padding: 0px;
  }
  &__modal {
    .flight-text-input__input {
      height: 40px;
    }
  }
  &__card {
    &__header {
      font-size: $font-size-medium;
      font-weight: $font-weight-bold;
      padding-bottom: $spacer-medium;
    }
    &__subheader {
      font-size: $font-size-small;
      color: #697186;
    }
    &__bottom {
      &__add {
        top: 14px;
        float: left;
        margin-right: 5px;
        border-radius: 50% !important;
        padding: 1px 12px !important;
        padding-bottom: 2px !important;
        position: relative;
      }
      &__container {
        display: flex;
        flex-wrap: wrap;
        font-size: $font-size-small;
        &__tabs {
          display: inline-block;
          margin-bottom: 10px;
          padding: 10px;
          background-color: #f2f3f5;
          color: $primary-black;
          border-radius: 5px;
          padding-top: $spacer-medium;
          padding-bottom: $spacer-medium;
          padding-left: $spacer-large;
          font-weight: $font-weight-bold;
          font-size: $font-size-small;
          flex-wrap: wrap;
          &--not-bold {
            color: $neutral-color-200;
            padding-left: $spacer-small;
            font-weight: $font-weight-regular;
          }
        }
        &__tabs:hover {
          background-color: #eee;
        }

        &__tabs--spacer {
          padding-left: $spacer-medium;
        }
        &__close {
          cursor: pointer;
          border-radius: 50%;
          color: #eee;
          background: #d3d3d3;
          position: relative;
          font-size: 10px;
          padding: 1px 5px;
          padding-bottom: 2px;
          bottom: 2px;
          margin-left: 5px;
        }

        &__close:hover {
          background: #bbb;
        }
      }
    }
  }
}
