@import '../../styles/variables';

.analytics-export {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid $neutral-color-100;

  &__card {
    margin-top: 30px;
    margin-left: 40px;

    &__wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__header {
      font-size: $font-size-medium;
      font-weight: $font-weight-bold;
      padding-bottom: $spacer-medium;
    }

    &__subheader {
      font-size: $font-size-small;
      color: #697186;
    }

    &__viewbox-loader {
      margin-left: -48px;
    }

    &__container {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      font-size: $font-size-small;

      &__tabs {
        display: inline-block;
        flex-wrap: wrap;
        margin-bottom: 10px;
        padding: 10px;
        background-color: #f2f3f5;
        color: $primary-black;
        border-radius: 5px;
        padding-top: $spacer-medium;
        padding-bottom: $spacer-medium;
        padding-left: $spacer-large;
        font-weight: $font-weight-bold;
        font-size: $font-size-small;
        flex-wrap: wrap;
        grid-gap: 2.5% 2.5%;

        &--disabled {
          color: $neutral-color-600;
          font-weight: $font-weight-regular;
        }
      }

      &__tabs--spacer {
        padding-left: $spacer-large;
      }

      &__link {
        font-size: $font-size-small;
        color: $primary-blue;
      }
    }
  }

  &__table {
    &__icon {
      margin-left: -30px;
    }

    .flight-table-row__cell {
      padding: $spacer - 3;

      &:first-child {
        width: 40%;
      }
    }
  }

  .array-fields {

    padding: 32px 0px;

    &__add-field {

      height: 32px;
      border-radius: $btn-border-radius;
      font-size: small;
      padding: 0 $spacer-xsmall * 5;

      &:hover, &:focus {
        color: map-get($primary-colors, "blue");
        background-color: map-get($neutral-colors, "50");
        svg {
          fill: map-get($primary-colors, "blue");
        }
      }
      
    } 

    .flight-text-input__input__clear-icon {
      &:hover, &:focus {
        outline: none; 
        path {
          fill: $red-500;
        }
      }
    }
  }

  .flight-modal-wrapper .flight-modal--medium {
    max-width: 450px !important;
  }

  .flight-modal__footer {
    padding: 0px !important;
  }


}
