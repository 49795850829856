@import '../../../styles/variables';

// Component
.conflicts-filter {
  &__list {
    display: flex;
    flex-direction: column;
    width: 214px;
    flex: 0 0 214px;
    height: 100%;
    min-height: 416px;
    max-height: 428px;
    box-shadow: inset -4px 0px 4px rgba(0, 0, 0, 0.1);
    &-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0px;
      height: 48px;
      border-bottom: 1px solid map-get($neutral-colors, '100');
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
    }
    &-wrapper {
      padding-inline-start: 0;
      padding: 8px 18px 8px 8px;
      margin: 0;
      max-height: 372px;
      overflow: auto;
    }
    &-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 8px 20px;

      width: 100%;
      height: 32px;
      border-radius: 5px;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: map-get($neutral-colors, '700');
      cursor: pointer;
      margin-bottom: 8px;
      transition: background-color 0.2s;
      &-icon {
        padding-right: 12px;
        svg {
          fill: map-get($neutral-colors, '700');
        }
      }
      &:hover,
      &.active {
        background-color: map-get($blues, '50');
        font-weight: 700;
      }
    }
  }
}
