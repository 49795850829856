@import 'styles/_variables';

.search-bar {
  position: relative;
  width: fit-content;

  &__icon {
    position: absolute;
    top: 35%;
    right: 0;
    transform: translate(-20px, -5px);
    background-color: none;

    &--with-error {
      right: 16px !important;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .flight-text-input-wrapper {
    .flight-text-input {
      &__input {
        padding-right: 40px;
        transition: all 0.2s ease-in-out;
        height: 40px;
        &:focus {
          background-color: $neutral-color-100;
        }
      }

      &__border {
        width: calc(100% - 8px);
      }
    }
  }
}
