@import 'styles/_variables';

.json-editor {
  display: flex;
  flex-direction: column;

  &__editor {
    display: flex;
    width: 600px;
  }

  &__line-numbers {
    display: flex;
    flex-direction: column;
    background-color: $neutral-color-100;
    border: 1px solid $neutral-color-300;
    border-right: none;
    height: 200px;
    max-height: 200px;
    overflow: hidden;
  }

  &__line-number {
    display: flex;
    justify-content: flex-end;
    font-size: $font-size-xsmall;
    padding: 0px 10px;
    line-height: 19px;
    background-color: $neutral-color-100;
    border-bottom: 1px solid $neutral-color-200;
  }

  &__text-area {
    padding-left: 5px;
    resize: none;
    width: 550px;
    height: 200px;
    font-size: 13px;
    border: 1px solid $neutral-color-300;

    &.error {
      outline: 2px solid $secondary-red;
      outline-offset: -2px;
    }
  }

  &__error-icon {
    fill: $secondary-red;
  }

  &__error-message {
    font-size: $font-size-xsmall;
    color: $secondary-red;
    margin: 5px 0 0 30px;
  }
}
