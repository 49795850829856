@import 'src/styles/variables';

.context-attribute-sidepanel {
  .flight-modal-wrapper {
    z-index: 99;
  }

  .flight-modal__content {
    min-height: 260px;
  }

  &__content {
    &__label {
      font-size: $font-size-small;
      font-weight: $font-weight-bold;
    }

    &__field {
      margin-bottom: 30px;
    }

    &__expiry {
      margin-bottom: 30px;
      &__select {
        max-width: 165px;
      }
    }

    &__expiry-duration {
      margin-left: -50px;
      &__select {
        max-width: 65px;
      }
    }

    &__expiry-unit {
      margin-left: -150px;
      &__select {
        max-width: 85px;
      }
    }

    &__text-area {
      min-width: 365px !important;
      width: 365px !important;
      margin-top: -30px;
      margin-bottom: 30px;
    }

    &__select {
      margin-bottom: 30px;
    }

    &__link {
      font-size: $font-size-small;
      cursor: pointer;
      font-weight: 700;
      color: $primary-blue;
      &--disabled {
        pointer-events: none;
        color: $neutral-color-200;
      }
    }

    &__ctx-id {
      width: 363px;
      height: 33px;
      border-radius: 2px;
      margin-top: 5px;
      border-radius: 5px;
      border: 0.5px solid $border-color;
      &__icon {
        cursor: pointer;
        padding: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 24px;
        height: 24px;
      }
      &__text {
        color: $neutral-color-700;
        margin-top: 6px;
        position: absolute;
        white-space: nowrap;
        overflow: hidden;
        max-width: 75%;
        text-overflow: ellipsis;
      }
      &__label {
        margin-top: 20px;
      }

      &__field {
        margin-top: 10px;
      }
    }

    &__existing-parameters {
      display: flex;
      justify-content: space-between;
      margin-top: -1px;
      height: 40px;
      border: 1px solid $neutral-color-200;
      padding: 9px;
      font-size: $font-size-small;
      &__name {
        color: $primary-blue;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      &__values {
        color: $neutral-color-400;
        font-size: $font-size-small;
      }
      &:hover {
        cursor: pointer;
        background: rgba(242, 243, 245, 0.5);
      }
    }

    &__edit-mode {
      &__description {
        font-size: $font-size-small;
        margin-bottom: $spacer-xlarge * 2;
      }

      &__headers {
        color: $neutral-color-600;
        font-weight: $font-weight-bold;
        font-size: $font-size-small;
        margin-bottom: $spacer-large;
      }

      &__values {
        font-size: $font-size-small;
        margin-bottom: $spacer-large * 2;

        &__scope {
          display: flex;

          &__icon {
            margin-right: 12px;
          }
        }
      }
    }

    &__snack-bar {
      position: relative !important;
      height: 60px !important;
      width: 100% !important;
      box-shadow: none !important;
      margin-bottom: $spacer-xlarge;
    }

    &__tenant-scope {
      display: flex;
      margin-left: 48px;
      padding: 12px;

      &__tooltip-icon {
        width: 18px;
        height: 18px;
        margin-left: 12px;
      }
    }

    .flight-text-input__input {
      width: 100%;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__button {
      margin-left: 10px;
      float: right;
    }
  }
}
