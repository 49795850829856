@import '../../../styles/variables';

.create-service-account-modal {
  .form-group {
    margin-bottom: 0;
  }

  .flight-text-input {
    margin-bottom: 4px;

    &__input {
      width: 100%;
    }
  }

  .flight-text-area {
    line-height: 0;

    span {
      display: none;
    }

    &__textarea {
      width: 100%;
      padding: 12px 8px;
      border: 0;
      border-bottom: 1px solid #b4b8c3;
      border-radius: 5px 5px 0 0;
      height: 24px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &__details {
      display: flex;
      justify-content: space-between;

      &__name {
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        margin-bottom: 10px;
      }
    }
  }

  &__copy-img {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    cursor: pointer;

    path {
      fill: $neutral-color-900;
    }

    &:hover {
      path {
        fill: $neutral-color-600;
      }
    }
  }

  &__copied-message {
    margin-left: 10px;
    font-weight: $font-weight-regular;
    color: $secondary-green;
    animation: fade-in .3s;
    animation-fill-mode: forwards;
  }

  &__error-message {
    color: $secondary-red;
    margin: 0;
  }

  &__field {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  &__label {
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    color: $neutral-color-900;
  }

  &__textarea {
    padding: 0;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-bottom: 0;
  }

  &__submit-button {
    margin-left: 20px;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
