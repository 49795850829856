@import '../../styles/variables';

.flybits-life-app-page {
  border-left: 1px solid $neutral-color-100;
  height: 100%;

  &__content {
    display: flex;
    gap: 40px;
    padding: 40px;

    h2 {
      font-size: larger;
      margin-bottom: $spacer-5xlarge;
    }

    &__screenshot-container {
      width: 50%;

      img {
        display: block;
        width: 100%;
        max-width: 400px;
        height: auto;
        margin: 0 auto;
        border-radius: 12px;
        border: 1px solid $neutral-color-100;
      }
    }

    &__instructions-container {
      li {
        margin-bottom: $spacer_5xlarge;

        > strong {
          font-size: large;
        }

        .note,
        .button-container,
        .qr-code-container,
        table {
          margin-top: $spacer-xlarge;
        }

        .note {
          color: map-get($neutral-colors, '600');
        }

        .qr-code-container {
          svg {
            display: block;
          }

          a {
            margin-top: $spacer-small;
            display: inline-block;
            font-size: $font-size-small;
            color: map-get($neutral-colors, '600');
          }

          &.is-disabled {
            opacity: 0.1;
          }
        }

        table {
          td {
            padding: $spacer-base;

            &:first-child {
              font-weight: bold;
            }

            .is-enabled {
              color: $success-color;
              background-color: #defcf5;
            }

            .is-disabled {
              color: $red-600;
              background-color: $red-100;
            }

            .not-set {
              color: map-get($neutral-colors, '800');
            }
          }
        }
      }
    }
  }
}
