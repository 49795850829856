@import '../../styles/variables';

.concierge-categories {
  padding: 3%;

  &__star {
    color: red;
  }

  &__search-bar {
    .flight-text-input-wrapper {
      width: 200px !important;
    }

    .search-bar__icon {
      top: 40%;
    }
  }

  &__table {
    max-height: 500px;
    margin-top: 40px;
    border: 1px solid $neutral-color-200;
    border-bottom: none;
    overflow: auto;

    .flight-table {
      min-width: 900px;

      .flight-table-header {
        position: sticky;
        z-index: 1;
        top: 0;
        font-weight: $font-weight-regular;
        color: $neutral-color-700;
        background-color: $neutral-color-50;
        border-bottom: 1px solid $neutral-color-200;
      }
    }

    &__app-visibility-order {
      font-size: $font-size-small;
      font-weight: $font-weight-bold;

      &__number {
        display: inline-block;
        width: 25%;
      }
      &__control {
        display: inline-block;

        &__icon {
          height: 16px;
          width: 16px;
          margin-right: 4px;
          padding: 2px;
          border: 1px solid $neutral-color-500;
          border-radius: 50%;
        }
      }
    }

    &__category-name {
      font-size: $font-size-small;
      font-weight: $font-weight-bold;

      &__other-languages {
        display: flex;
        flex-direction: column;
        justify-content: center;

        > :last-child {
          margin-bottom: 0 !important;
        }

        div {
          vertical-align: sub;
        }

        div > span.categoryLanguage {
          font-weight: $font-weight-bold;
          margin: 0;
        }

        div > span.rtl {
          unicode-bidi: embed;
          direction: rtl;
        }
      }
    }

    &__labels {
      display: flex;
      flex-wrap: wrap;
      max-width: 500px;

      .flight-label {
        margin-right: $spacer-medium;
      }
    }

    &__modify-icons {
      display: flex;
      justify-content: space-around;
    }
  }

  &__add-category-table {
    border: 1px solid $neutral-color-200;
    overflow: hidden;

    .flight-table {
      min-width: 900px;

      .flight-table-header {
        display: none;
      }

      .flight-table-row__cell:first-child {
        border-bottom: none;
      }
    }

    &__add-category {
      font-size: $font-size-small;
      cursor: pointer;
      font-weight: $font-weight-bold;
      color: $primary-blue;
      display: flex;
      justify-items: flex-start;
    }
  }

  &__modal-class {
    min-width: 948px !important;

    .flight-modal__footer {
      .modal-footer {
        padding: 0px;
        border-top: none;
      }
    }

    .flight-label-search {
      input::placeholder {
        opacity: 1;
      }
    }
  }

  &__modal {
    min-height: 300px;

    &__multi-non-english-block {
      display: flex;
      flex-direction: column;
      &__button {
        display: flex;
        justify-content: flex-end;
      }
    }

    &__label {
      font-weight: $font-weight-bold;
      display: block;
      margin-bottom: 0;
      padding-bottom: 25px;
    }
    label {
      margin-bottom: 0;
    }

    &__category-name {
      input {
        width: 360px;
      }

      &__block {
        display: flex;
        flex-direction: column;
      }
      &__title {
        padding-bottom: 4px;
        margin-bottom: 0;
      }
      &__non-english {
        input {
          width: 100%;
          padding-right: $spacer-medium;
        }
      }
      &__multi-non-english-input {
        padding-bottom: 20px;
        padding-right: 44px;

        .flight-text-input-wrapper {
          width: 360px;
        }

        input::placeholder {
          opacity: 1;
        }
      }
    }

    &__content-labels {
      .flight-multi-select__dropdown__trigger__button {
        width: 350px;
        justify-content: space-between;
      }
    }

    &__content-block {
      padding-bottom: 30px;
      margin-right: 84px;
      padding-left: 20px;
      .open {
        padding-bottom: 200px;
      }

      &__grid {
        display: grid;
        grid-template-columns: 50% 50%;
      }

      .flight-text-input-wrapper {
        width: 360px;
        input {
          width: 100%;
        }
      }
    }
  }

  &__confirm-navigation-modal {
    &__subheader {
      font-size: $font-size-medium;
      padding-bottom: $spacer-medium;
      margin-bottom: $spacer-2xlarge;
    }
  }
}
