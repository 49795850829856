@import 'styles/_variables';

.context-attributes {
  padding: 3%;

  &__table {
    max-height: 500px;
    margin-top: 40px;
    border: 1px solid $neutral-color-200;
    overflow: auto;

    .flight-table {
      min-width: 900px;

      .flight-table-header {
        position: sticky;
        z-index: 1;
        top: 0;
        font-weight: $font-weight-regular;
        color: $neutral-color-700;
        background-color: $neutral-color-50;
        border-bottom: 1px solid $neutral-color-200;
      }

      ul.flight-overflow-menu__dropdown-list--opened {
        position: fixed;
        right: 50px;
      }
    }

    .flight-checkbox {
      position: relative !important;
    }

    &__icon {
      margin-left: 10px;
    }

    &__name {
      font-size: 14px;
      font-weight: 700;
      color: $primary-blue;
      cursor: pointer;
    }

    &__last-modified {
      font-size: 12px;
      font-weight: 400;
      color: $primary-black;
    }

    &__options {
      .flight-overflow-menu__dropdown-list {
        &__group__option:last-of-type {
          border-top: 1px solid $neutral-color-100;
          color: $secondary-red;

          &:hover {
            color: $primary-white;
            background-color: $secondary-red;
          }
        }
      }
    }

    &__tooltip {
      .flight-tooltip__tooltip {
        max-width: 260px;
      }
    }

    &__status {
      &__indicator {
        position: static;
        width: 8px;
        height: 8px;
        left: 0px;
        border-radius: 50%;
        &--active {
          background: $success-color;
        }
        &--inactive {
          background: $error-color;
        }
      }
      &__text {
        margin-left: 15px;
        margin-top: -15px;
        &--active {
          color: $success-color;
        }
        &--inactive {
          color: $error-color;
        }
      }
    }

    &__attributes {
      &__count {
        margin-right: $spacer-small;
      }
      &__action {
        cursor: pointer;
        color: $primary-blue;
        margin-left: $spacer-small;
        font-size: $font-size-small;
      }
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    &__search-bar {
      margin-top: 9px;
    }
    &__select {
      margin-left: 20px;
    }
    &__btn {
      margin-left: 20px;
    }
  }
}
