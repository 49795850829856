.create-or-manage-content-template {
  height: 100%;
  width: 100%;
  .search-bar {
    .flight-text-input-wrapper {
      .flight-text-input__border {
        width: 100%;
      }
    }
  }
  .flight-text-input__input {
    width: 100%;
  }
  .content-template-builder {
    height: 100%;
    width: 100%;
    &__fields {
      .search-bar{
        width: 100%;
        .flight-text-input__input {
          padding-right: 20px;
          width: 100%;
        }
      }
    }
  }
  .loading__icon_wrapper {
    width: 100%;
    height: 100%;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  svg {
    vertical-align: baseline;
  }
}
