@import '../../../styles/variables';

.account-settings-modal {
  &__content {
    padding-left: $spacer-xlarge;
    padding-right: $spacer-xlarge;
    &__warning-banner {
      width: 453px;
      height: 44px;
      background-color: $yellow-50;
      padding: $spacer-large;
      &__icon {
        margin-right: $spacer-small;
      }
      &__text {
        font-size: $font-size-small;
        color: $neutral-color-900;
      }
    }
    &__text-description {
      padding-top: $spacer-2xlarge;
      padding-bottom: $spacer-xlarge * 2;

      font-size: $font-size-small;
      color: $neutral-color-900;
    }
    &__radio-btn-grp {
        padding-bottom: $spacer-2xlarge;
        &__radio-btn {
            padding-bottom: $spacer-xlarge;
        }
    }
  }

  .flight-modal-wrapper .flight-modal--small {
    z-index: 4 !important;
    max-width: 501px !important;
  }

  .flight-modal-wrapper .flight-modal .flight-modal__content {
    overflow-x: hidden !important;
  }

  .flight-modal__footer {
    padding: 0px !important;
  }

  .flight-modal__footer {
    padding-top: 10px;
  }
}
