@import 'src/styles/variables';

$MAIN_CLASS: 'not_found';
$CONTENT_CLASS: $MAIN_CLASS + '__content';
$FIGURE_CLASS: $MAIN_CLASS + '__figure';
$ITEM_CLASS: $CONTENT_CLASS + '__item';

.#{$MAIN_CLASS} {
  left: 0;
  top: 0;
  position: fixed;
  z-index: 95;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.#{$CONTENT_CLASS} {
  width: 50vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__wrapper {
    max-width: 400px;
  }
  &__heading-text {
    margin-top: $spacer_7xlarge;
    margin-bottom: $spacer_xlarge;
  }
  &__description-text {
    text-align: left;
    margin-top: 24px;
  }
  &__button {
    margin-top: $spacer_5xlarge;
    margin-bottom: $spacer_2xlarge;
    padding-left: $spacer_7xlarge;
    padding-right: $spacer_7xlarge;
  }
}
.#{$FIGURE_CLASS} {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
